export default [
  // Stages
  {
    path: '/events/stages',
    name: 'events-stages',
    component: () => import('./views/Dash/Events/Stages/List.vue'),
    meta: { title: 'Events - Stages' },
  },

  {
    path: '/events/stages/:id',
    name: 'events-stages-edit',
    component: () => import('./views/Dash/Events/Stages/Form.vue'),
    meta: { title: 'Events - Stages Edit' },
  },

  {
    path: '/events/stages/:id/videos',
    name: 'events-stages-videos',
    component: () => import('./views/Dash/Events/Stages/Video.vue'),
    meta: { title: 'Events - Stages Edit Vídeos' },
  },

  // Schedule
  {
    path: '/events/schedule',
    name: 'events-schedule',
    component: () => import('./views/Dash/Events/Schedule/List.vue'),
    meta: { title: 'Events - Schedule' },
  },

  {
    path: '/events/schedule/:id',
    name: 'events-schedule-edit',
    component: () => import('./views/Dash/Events/Schedule/Form.vue'),
    meta: { title: 'Events - Schedule Edit' },
  },

  {
    path: '/events/schedule/create',
    name: 'events-schedule-create',
    component: () => import('./views/Dash/Events/Schedule/Form.vue'),
    meta: { title: 'Events - Schedule Edit' },
  },

  // Talents
  {
    path: '/events/talents',
    name: 'events-talents',
    component: () => import('./views/Dash/Events/Talents/List.vue'),
    meta: { title: 'Events - Talents' },
  },
  {
    path: '/events/talents/create',
    name: 'events-talents-create',
    component: () => import('./views/Dash/Events/Talents/Form.vue'),
    meta: { title: 'Events - Talents Edit' },
  },
  {
    path: '/events/talents/:id',
    name: 'events-talents-edit',
    component: () => import('./views/Dash/Events/Talents/Form.vue'),
    meta: { title: 'Events - Talents Edit' },
  },

  // Talent categories
  {
    path: '/events/talent-categories',
    name: 'events-talent-categories',
    component: () => import('./views/Dash/Events/TalentCategories/List.vue'),
    meta: { title: 'Events - Talents categories' },
  },
  // {
  //   path: '/events/talent-categories/:id',
  //   name: 'events-talent-categories-edit',
  //   component: () => import('./views/Dash/Events/TalentCategories/Form.vue'),
  //   meta: { title: 'Events - Talents categories Edit' },
  // },
];
