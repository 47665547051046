import en from '../../translations/en';

const getErrorMessage = (e) => {
  try {
    if (typeof e === 'string') return e;

    if (!e) return 'Error';

    const { message, body } = e;

    if (body && body.error) {
      const key = `messages.${body.error}`;

      if (en.messages[body.error]) {
        return key;
      }

      return body.error;
    }

    return message || e;
  } catch (err) {
    return err.message || 'Error';
  }
};

const defaultProps = {
  autoHideDelay: 5000,
  appendToast: false,
};

export default {
  error: (ctx, message, title) => (
    ctx.$bvToast.toast(
      ctx.$t(getErrorMessage(message)),
      {
        title: ctx.$t(title || 'messages.errorOccurred'),
        variant: 'danger',
        ...defaultProps,
      },
    )
  ),

  warn: (ctx, message, title) => (
    ctx.$bvToast.toast(
      ctx.$t(getErrorMessage(message)),
      {
        title: title ? ctx.$t(title) : 'CCXP Worlds 2021',
        variant: 'warning',
        ...defaultProps,
      },
    )
  ),

  success: (ctx, message, title) => (
    ctx.$bvToast.toast(
      ctx.$t(getErrorMessage(message)),
      {
        title: title ? ctx.$t(title) : 'CCXP Worlds 2021',
        variant: 'success',
        ...defaultProps,
      },
    )
  ),
};
