import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { STORAGE } from '../assets/js/consts';

export default {
  login: async (model) => (
    Vue.http.post('auth', model).then((res) => res.json())
  ),

  recoverPassword: async (lang, email) => (
    Vue.http.get(`auth/recover/?lang=${lang}&email=${email}`).then((res) => res.json())
  ),

  registerNewPassword: async (model) => (
    Vue.http.post('auth/register-password', model).then((res) => res.json())
  ),

  getUser() {
    return JSON.parse(localStorage.getItem(STORAGE.USER));
  },

  setLogin: (jwt) => {
    if (!jwt) return;

    localStorage.setItem(STORAGE.TOKEN, jwt);
  },

  logout: async (forceRedirect = true) => {
    try {
      Sentry.configureScope((scope) => scope.setUser(null));
      localStorage.removeItem(STORAGE.USER);

      localStorage.clear();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      localStorage.clear();

      if (forceRedirect) {
        console.log('force redirect here');

        window.location.href = `${Vue.prototype.VUE_APP_BASE_URL}/login`;
      }
    }
  },

  // TODO
  canAccessRoute: (to) => {
    const permissions = JSON.parse(localStorage.getItem('X-Token')).permissoes;
    let canAccess = false;

    console.log(permissions);

    permissions.map((item) => {
      if (to.includes(item.url)) canAccess = true;

      return item;
    });

    return canAccess;
  },

  getToken: () => localStorage.getItem(STORAGE.TOKEN),

  isLoggedIn: () => !!localStorage.getItem(STORAGE.TOKEN),
};
