<template>
  <div class="form-view bg-dark">
    <div class="center text-center">
      <router-link :to="{ name: 'login' }">
        <img alt="CCXP logo" src="/img/logo.png" class="mb-4">
      </router-link>

      <b-container>
        <div class="mx-auto bg-light border p-3 rounded" style="width: 300px;">

          <form v-on:submit.prevent="recover">
            <b-input-group :prepend="$t('inputs.email')" class="mb-3">
              <b-form-input
                required
                type="email"
                v-model="model.email"
              />
            </b-input-group>

            <b-row align-v="end">
              <b-col class="text-left">
                <router-link :to="{ name: 'login' }">
                  <small>{{ $t('buttons.login') }}</small>
                </router-link>
              </b-col>

              <b-col>
                <b-button
                  type="submit"
                  variant="dark"
                  class="float-right"
                  :disabled="loading"
                >
                  {{ loading ? `${$t('messages.loading')}...` : $t('buttons.recover') }}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Auth from '../services/Auth';
import Toast from '../assets/js/toast';

export default {
  data() {
    const data = {
      model: {
        email: '',
      },

      loading: false,
    };

    return data;
  },

  beforeRouteEnter(to, from, next) {
    if (Auth.isLoggedIn()) {
      next({ path: '' });
      return;
    }

    next();
  },

  beforeCreate() {
    Vue.http.headers.common.Authorization = '';
  },

  methods: {
    async recover() {
      try {
        if (this.loading) return;

        this.loading = true;

        await Auth.recoverPassword(this.$i18n.locale, this.model.email);

        Toast.success(this, 'messages.recoverPasswordInstructions');
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
