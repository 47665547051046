import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import Auth from './services/Auth';
import Login from './views/Login.vue';
import ForgetPassword from './views/ForgetPassword.vue';
import RegisterNewPassword from './views/RegisterNewPassword.vue';
import NotAllowed from './views/NotAllowed.vue';
import routerEvents from './router-events';

Vue.use(VueRouter);
Vue.use(VueResource);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgetPassword,
  },
  {
    path: '/register-new-password',
    name: 'register-new-password',
    component: RegisterNewPassword,
  },
  {
    path: '/not-allowed',
    name: 'not-allowed',
    component: NotAllowed,
  },
  {
    path: '/',
    component: () => import('./views/Dash/Wrap.vue'),
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('./views/Dash/Home.vue'),
        meta: { title: 'Home' },
      },

      // Users -> User management
      {
        path: '/users/manage',
        name: 'manage-users',
        component: () => import('./views/Dash/Users/Management/List.vue'),
        meta: { title: 'Users - Manage' },
      },

      {
        path: '/users/manage/create',
        name: 'manage-users-create',
        component: () => import('./views/Dash/Users/Management/Form.vue'),
        meta: { title: 'Users - Create' },
      },

      {
        path: '/users/manage/:id',
        name: 'manage-users-edit',
        component: () => import('./views/Dash/Users/Management/Form.vue'),
        meta: { title: 'Users - Edit' },
      },

      // General -> Cache
      {
        path: '/cache',
        name: 'general-cache',
        component: () => import('./views/Dash/General/Cache/List.vue'),
        meta: { title: 'Cache - List' },
      },

      // General -> Customer
      {
        path: '/customers',
        name: 'general-customers',
        component: () => import('./views/Dash/General/Customers/List.vue'),
        meta: { title: 'Customers - List' },
      },

      // General -> Push
      {
        path: '/general/push',
        name: 'general-push',
        component: () => import('./views/Dash/General/Push/List.vue'),
        meta: { title: 'General - Push' },
      },

      {
        path: '/general/push/create',
        name: 'general-push-create',
        component: () => import('./views/Dash/General/Push/Form.vue'),
        meta: { title: 'General - Push Create' },
      },
      {
        path: '/general/push/:id',
        name: 'general-push-edit',
        component: () => import('./views/Dash/General/Push/Form.vue'),
        meta: { title: 'General - Push Edit' },
      },

      // General -> Artists valley
      {
        path: '/artists/artists-valley',
        props: { type: 'artists-valley' },
        name: 'general-artists',
        component: () => import('./views/Dash/Artists/Management/List.vue'),
        meta: { title: 'Artists valley - List' },
      },

      {
        path: '/artists/artists-valley/create',
        props: { type: 'artists-valley' },
        name: 'general-artists-valley-create',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Artists valley - Create' },
      },

      // General -> Studios
      {
        path: '/artists/studios',
        props: { type: 'studios' },
        name: 'general-studios',
        component: () => import('./views/Dash/Artists/Management/List.vue'),
        meta: { title: 'Studios - List' },
      },

      {
        path: '/artists/studios/create',
        props: { type: 'studios' },
        name: 'general-studios-create',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Studios - Create' },
      },

      {
        path: '/artists/studios/:id',
        props: { type: 'studios' },
        name: 'general-studios-edit',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Studios - Edit' },
      },

      // General -> Experiences
      {
        path: '/artists/experiences',
        props: { type: 'experiences' },
        name: 'general-experiences',
        component: () => import('./views/Dash/Artists/Management/List.vue'),
        meta: { title: 'Experiences - List' },
      },

      {
        path: '/artists/experiences/create',
        props: { type: 'experiences' },
        name: 'general-experiences-create',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Experiences - Create' },
      },

      {
        path: '/artists/experiences/:id',
        props: { type: 'experiences' },
        name: 'general-experiences-edit',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Experiences - Edit' },
      },

      // General -> Specials
      {
        path: '/artists/specials',
        props: { type: 'specials' },
        name: 'general-specials',
        component: () => import('./views/Dash/Artists/Management/List.vue'),
        meta: { title: 'Special - List' },
      },

      {
        path: '/artists/specials/create',
        props: { type: 'specials' },
        name: 'general-specials-create',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Special - Create' },
      },

      {
        path: '/artists/specials/:id',
        props: { type: 'specials' },
        name: 'general-specials-edit',
        component: () => import('./views/Dash/Artists/Management/Form.vue'),
        meta: { title: 'Special - Edit' },
      },

      {
        path: '/artists/studios-playlist',
        props: { type: 'studios-playlist' },
        name: 'general-studios-playlist',
        component: () => import('./views/Dash/Artists/Management/StudiosPlaylist.vue'),
        meta: { title: 'Studios - Playlist' },
      },

      // General -> Downloads
      {
        path: '/general/downloads',
        name: 'general-downloads',
        component: () => import('./views/Dash/General/Downloads/List.vue'),
        meta: { title: 'General - Downloads' },
      },
      {
        path: '/general/downloads/create',
        name: 'general-downloads-create',
        component: () => import('./views/Dash/General/Downloads/Form.vue'),
        meta: { title: 'Downloads - Create' },
      },
      {
        path: '/general/downloads/:id',
        name: 'general-downloads-edit',
        component: () => import('./views/Dash/General/Downloads/Form.vue'),
        meta: { title: 'Downloads - Edit' },
      },

      // General -> Redirect
      {
        path: '/general/redirect',
        name: 'general-redirect',
        component: () => import('./views/Dash/General/Redirect/Form.vue'),
        meta: { title: 'General - Redirect' },
      },

      // MercadoLivre -> Stores
      {
        path: '/mercadolivre/stores',
        name: 'mercadolivre-stores',
        props: { type: 'stores' },
        component: () => import('./views/Dash/MercadoLivre/Management/List.vue'),
        meta: { title: 'Mercado Livre - List' },
      },

      {
        path: '/mercadolivre/stores/create',
        name: 'mercadolivre-stores-create',
        props: { type: 'stores' },
        component: () => import('./views/Dash/MercadoLivre/Management/Form.vue'),
        meta: { title: 'Mercado Livre - Create' },
      },

      {
        path: '/mercadolivre/stores/:id',
        name: 'mercadolivre-stores-edit',
        props: { type: 'stores' },
        component: () => import('./views/Dash/MercadoLivre/Management/Form.vue'),
        meta: { title: 'Mercado Livre - Edit' },
      },

      ...routerEvents,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

Vue.http.interceptor.before = (req) => {
  if (req.url.indexOf('https://') === -1) {
    const token = Auth.getToken() || '';
    req.headers.set('Authorization', token);
  }
};

Vue.http.interceptors.push((req, next) => {
  next((res) => {
    const { token = '' } = res.body || {};

    switch (res.status) {
      default:
      case 200:
      case 201:
      case 204:
        if (token) {
          Sentry.configureScope((scope) => {
            scope.setExtra('token', token);
          });

          Auth.setLogin(token);

          console.log('set login!');
        }

        break;

      case 403:
        router.push({ name: 'not-allowed' });
        break;

      case 401:
        Auth.logout(false);
        router.push({ name: 'login' });
        break;
    }
  });
});

router.beforeEach((to, from, next) => {
  document.title = `${(to.meta.title || 'Painel')} | CCXP Worlds 2021`;

  const needAuth = to.matched.some((m) => m.meta.auth);
  const isLoggedIn = Auth.isLoggedIn();

  if (!isLoggedIn && needAuth) {
    Auth.logout(false);

    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
