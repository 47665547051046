<template>
  <div class="form-view bg-dark">
    <div class="center text-center">
      <router-link :to="{ name: 'login' }">
        <img alt="CCXP logo" src="/img/logo.png" class="mb-4">
      </router-link>

      <b-container>
        <div class="mx-auto bg-light border p-3 rounded" style="width: 300px;">

          <form v-on:submit.prevent="recover">
            <b-input-group :prepend="$t('inputs.password')" class="mb-3">
              <b-form-input
                required
                type="password"
                v-model="model.password"
                pattern=".{6,25}"
                :title="$t('messages.invalidPasswordLength')"
              />
            </b-input-group>

            <b-input-group :prepend="$t('inputs.passwordConfirm')" class="mb-3">
              <b-form-input
                required
                type="password"
                v-model="model.passwordConfirm"
              />
            </b-input-group>

            <b-row align-v="end">
              <b-col class="text-left" v-if="showButtonLogin">
                <router-link :to="{ name: 'login' }">
                  {{ $t('buttons.login') }}
                </router-link>
              </b-col>

              <b-col>
                <b-button
                  type="submit"
                  variant="dark"
                  class="float-right"
                  :disabled="loading"
                >
                  {{ loading ? `${$t('messages.loading')}...` : $t('buttons.save') }}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Auth from '../services/Auth';
import Toast from '../assets/js/toast';

export default {
  data() {
    const data = {
      model: {
        password: '',
        passwordConfirm: '',
      },

      showButtonLogin: false,
      loading: false,
    };

    return data;
  },

  beforeRouteEnter(to, from, next) {
    if (Auth.isLoggedIn()) {
      next({ path: '' });
      return;
    }

    next();
  },

  beforeCreate() {
    Vue.http.headers.common.Authorization = '';
  },

  mounted() {
    this.checkToken();
  },

  methods: {
    async checkToken() {
      const { query } = this.$route;

      if (!query || !query.token || query.token.length < 100) {
        this.$router.push({ name: 'login' });
      }
    },

    async recover() {
      try {
        if (this.loading) return;

        const { password, passwordConfirm } = this.model;

        if (password !== passwordConfirm) {
          Toast.error(this, 'messages.passwordNotEquals');
          return;
        }

        this.loading = true;

        const obj = {
          password,
          salt: this.$route.query.token,
        };

        await Auth.registerNewPassword(obj);

        Toast.success(this, 'messages.newPasswordSuccess');
        this.showButtonLogin = true;

        this.model.password = '';
        this.model.passwordConfirm = '';

        setTimeout(() => {
          this.$router.push({ name: 'login' });
        }, 3000);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
