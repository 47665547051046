import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueI18n from 'vue-i18n';
import vSelect from 'vue-select';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueRx from 'vue-rx';
import { clipperFixed, clipperBasic, clipperPreview } from 'vuejs-clipper';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';

import App from './App.vue';
import router from './router';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRx);
Vue.use(VueI18n);
Vue.component('v-clipper-fixed', clipperFixed);
Vue.component('v-clipper-basic', clipperBasic);
Vue.component('v-clipper-preview', clipperPreview);
Vue.component('v-select', vSelect);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: Number(process.env.VUE_APP_SENTRY_RATE),
});

Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.http.options.root = process.env.VUE_APP_API_URL;
Vue.prototype.VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;
Vue.prototype.VUE_APP_S3_URL = process.env.VUE_APP_S3_URL;
Vue.prototype.VUE_APP_FRONT_URL = process.env.VUE_APP_FRONT_URL;
Vue.prototype.VUE_APP_RECAPTCHA_KEY = process.env.VUE_APP_RECAPTCHA_KEY;

if (process.env.NODE_ENV === 'production') {
  window.console.log = () => null;
  window.console.info = () => null;
  window.console.debug = () => null;
  window.console.warn = () => null;
}

const i18n = new VueI18n({
  locale: 'pt',
  messages: {
    // eslint-disable-next-line global-require
    en: require('./translations/en').default,
    // eslint-disable-next-line global-require
    pt: require('./translations/pt').default,
  },
});

new Vue({
  http: { root: '/root' },
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
