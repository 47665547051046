<template>
  <div class="form-view bg-dark">
    <div class="center text-center">
      <img alt="CCXP logo" src="/img/logo.png" class="mb-4">

      <b-container>
        <div class="mx-auto bg-light border p-3 rounded" style="width: 300px;">

          <form v-on:submit.prevent="login">
            <b-input-group :prepend="$t('inputs.email')" class="mb-3">
              <b-form-input
                required
                type="email"
                v-model="model.email"
              />
            </b-input-group>

            <b-input-group :prepend="$t('inputs.passwordLogin')" class="mb-3">
              <b-form-input
                required
                type="password"
                v-model="model.password"
              />
            </b-input-group>

            <b-row align-v="end">
              <b-col class="text-left">
                <router-link :to="{ name: 'forget-password' }">
                  <small>{{ $t('buttons.recoverPassword') }}</small>
                </router-link>
              </b-col>

              <b-col>
                <b-button
                  type="submit"
                  variant="dark"
                  class="float-right"
                  :disabled="loading"
                >
                  {{ loading ? `${$t('messages.loading')}...` : $t('buttons.login') }}
                </b-button>
              </b-col>
            </b-row>
          </form>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Auth from '../services/Auth';
import Toast from '../assets/js/toast';
import { STORAGE } from '../assets/js/consts';

export default {
  data() {
    return {
      model: {
        email: '',
        password: '',
        captcha: '',
      },

      loading: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    if (Auth.isLoggedIn()) {
      next({ path: '' });
      return;
    }

    next();
  },

  beforeCreate() {
    Vue.http.headers.common.Authorization = '';
  },

  methods: {
    async login() {
      try {
        if (this.loading) return;

        this.loading = true;
        this.model.captcha = await this.executeRecaptcha();

        const res = await Auth.login(this.model);

        if (res.ok) {
          const {
            language,
            username,
            id,
            // eslint-disable-next-line camelcase
            ip_address,
            type,
          } = res;

          const user = {
            email: this.model.email,
            username,
            id,
            ip_address,
            type,
            language,
          };

          localStorage.setItem(STORAGE.USER, JSON.stringify(user));

          Sentry.configureScope((scope) => {
            scope.setUser({
              email: user.email,
              username: user.username,
              id: `${user.id}`,
              ip_address,
            });

            scope.setExtra('language', user.language);
            scope.setExtra('type', user.type);
          });

          this.$i18n.locale = language;
          this.$router.push('/');

          return;
        }

        throw new Error('messages.invalidLogin');
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.loading = false;
      }
    },

    executeRecaptcha() {
      return new Promise((resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(
            process.env.VUE_APP_RECAPTCHA_KEY,
            { action: 'login' },
          ).then(resolve);
        });
      });
    },
  },
};
</script>
