export default {
  inputs: {
    email: 'E-mail',
    module: 'Módulo',
    moduleInfo: 'Cadastre e ordene os módulos que aparecerão na sua página',
    qty: 'Itens cadastrados',
    password: 'Alterar senha',
    passwordLogin: 'Senha',
    passwordConfirm: 'Repita sua senha',
    newPassword: 'Nova senha',
    name: 'Nome',
    active: 'Ativo',
    published: 'Publicado',
    createdAt: 'Data/Hora cadastro',
    action: 'Ação',
    typeSearch: 'Digite para buscar',
    all: 'Todos',
    filterBy: 'Filtrar por',
    language: 'Idioma',
    preferedLanguage: 'Idioma preferencial',
    profileType: 'Tipo de perfil',
    emptyDropdown: 'Nenhum resultado encontrado',
    thumb: 'Miniatura',
    type: 'Tipo',
    videoId: 'Video ID',
    captionEN: 'Descrição / EN-US',
    captionPT: 'Descrição / PT-BR',
    filled: 'Preenchido',
    customersReached: 'Clientes atingidos',
    title: 'Título',
    titleEN: 'Título / EN-US',
    titlePT: 'Título / PT-BR',
    agreementSigned: 'Contrato assinado',
    acceptAgreement: 'Aceitar contrato',
    namePT: 'Título do item / PT-BR',
    nameEN: 'Título do item / EN-US',
    categoryPT: 'Categoria / PT-BR',
    categoryEN: 'Categoria / EN-US',
    price: 'Preço',
    currency: 'Moeda',
    credential: 'Credencial',
    url: 'URL / Link',
    urlPT: 'URL / Link / PT-BR',
    urlEN: 'URL / Link / EN-BR',
    embed: 'Embed',
    embedLive: 'Live',
    embedChat: 'Chat',
    embedBackup: 'Backup',
    resolutionInstruction: '* resolução mínima: ',
    descriptionEN: 'Descrição EN',
    descriptionPT: 'Descrição PT',
    live: 'Ao vivo',
    fanCamLink: 'URL da Fancam',
    events: 'Eventos',
    period: 'Período',
    talents: 'Talentos',
    stage: 'Palco',
    icon: 'Ícone',
    categories: 'Categorias',
    salve: 'Salve',
    inbox: 'Inbox',
    oneonone: 'One on One',
    meetGreet: 'Sala em Grupo',
    autograph: 'Autógrafo',
    changePicture: 'Trocar imagem',
    stagePicture: 'Imagem que aparecerá quando não tiver transmissão ao vivo',
    picture: 'Imagem',
    storeLink: 'Loja',
    liveId: 'Id da live',
    featured: 'Destaque',
    order: 'Ordem',
    external: 'Mesa virtual externa',
    externalURL: 'URL externa',
    adultOnly: 'Somente adultos',
    subtitle: 'Legenda',
    time: 'Tempo',
    sent: 'Enviado',
    message: 'Mensagem',
    needModeration: 'Precisa de moderação',
    selectImage: 'Selecione uma imagem',
    selectIcon: 'Selecione um ícone (máx 90x30px)',
    selectFile: 'Selecione um arquivo',
    owner: 'Dono',
    category: 'Categoria',
  },

  placeholder: {
    order: 'Ordenação exclusiva para os destaques',
    liveUrl: 'insira o ID da sua live no Youtube',
    name: 'insira o seu nome',
    email: 'insira o seu e-mail',
    password: 'insira uma nova senha',
    aboutEN: 'insira uma descrição em inglês',
    aboutPT: 'insira uma descrição em português',
    country: 'selecione seu país',
    twitter: 'insira a url do seu twitter',
    facebook: 'insira a url do seu facebook',
    instagram: 'insira a url do seu instagram',
    tiktok: 'insira a url do seu tiktok',
    discord: 'insira a url do seu discord',
    twitch: 'insira a url do seu twitch',
    youtube: 'insira a url do seu canal no youtube',
    tagsDisclaimer: 'selecione até 10 tags',
    nameEN: 'insira um título em inglês',
    namePT: 'insira um título em português',
    descriptionPT: 'insira uma descrição em português',
    descriptionEN: 'insira uma descrição em inglês',
    messagePT: 'insira uma descrição em português',
    messageEN: 'insira uma descrição em inglês',
    videoId: 'insira o id do vídeo',
    url: 'insira a url para o marketplace de sua escolha',
    titleEN: 'insira um título em inglês',
    titlePT: 'insira um título em português',
    captionEN: 'insira uma descrição em inglês',
    captionPT: 'insira uma descrição em português',
    link: 'insira a url da imagem',
    embedBackup: 'Utilizar somente caso o Vimeo esteja fora do ar',
    storeLink: 'Informe o link da sua loja',
  },

  buttons: {
    add: 'Adicionar',
    recover: 'Recuperar',
    recoverPassword: 'Esqueci minha senha',
    create: 'Criar',
    delete: 'Excluir',
    edit: 'Editar',
    logout: 'Sair',
    login: 'Entrar',
    cancel: 'Cancelar',
    clear: 'Limpar',
    back: 'Voltar',
    save: 'Salvar',
    ok: 'Ok',
    goHome: 'Ir para a home',
    selectFile: 'Selecione um arquivo',
    sign: 'Assinar',
    removeAll: 'Deletar tudo',
    refresh: 'Atualizar',
    saveall: 'Salvar todos',
    resetall: 'Resetar todos',
    editall: 'Editar todos',
  },

  general: {
    cropTitle: 'Recorte sua imagem',
    howToCrop: 'Instruções de crop aqui',
    contact: 'Contato / Redes sociais',
    edit: 'Edição',
    yes: 'Sim',
    no: 'Não',
    agreementTitle: 'Assine o contrato',
    blockedTitle: 'Atenção',
    create: 'Criação',
  },

  messages: {
    invalidCode: 'Por favor, verifique o formato e cadastre apenas o ID da live no Youtube',
    loading: 'Carregando',
    emptyTable: 'Nenhum item',
    emptyFilteredTable: 'Nada encontrado na sua busca',
    notAllowed: 'Você não tem permissão para acessar este recurso',
    recoverPasswordInstructions: 'Siga as instruções enviadas por e-mail para cadastrar sua nova senha.',
    notRequested: 'Ocorreu um erro um erro ao cadastrar sua nova senha.',
    invalidLogin: 'E-mail ou senha incorretos.',
    invalidPasswordLength: 'Obrigatório entre 6 e 25 caracteres.',
    newPasswordSuccess: 'Nova senha cadastrada com sucesso. Efetue o login.',
    passwordNotEquals: 'As senhas informadas não são iguais.',
    errorOccurred: 'Ocorreu um erro',
    removeSuccess: 'Excluído com sucesso',
    saveSuccess: 'Salvo com sucesso',
    deleteConfirmMessage: 'Tem certeza que deseja excluir?',
    deleteConfirmMessageAlert: 'Essa operação não poderá ser desfeita.',
    scheduleConflict: 'Atenção, conflito de agenda! Você tem mais de um evento cadastrado no mesmo horário.',
    startTimeIsBiggerThanEnd: 'O horário de início é igual ou maior que a hora final',
    userExists: 'Já existe um usuário com esse e-mail.',
    userNotExists: 'O usuário não existe.',
    userIsMaster: 'O usuário é administrador.',
    tagExists: 'Já existe uma tag com esse nome.',
    imageTooSmall: 'A imagem selecionada é muito pequena',
    linkWithoutThumb: 'Vídeo do tipo Link precisa fornecer a miniatura',
    artistsValleyAgreement: `<strong>CONTRATO DE PARTICIPAÇÃO NA CCXP WORLDS - 2021</strong>
<br /><br />
Pelo presente instrumento e na melhor forma de direito, entre as partes:
<br /><br />
<strong>CCXP EVENTOS LTDA.</strong>, inscrita no CNPJ/MF sob o nº 19.641.956/0001-09, com sede na rua Ceará, nº480 – sala 11, Bairro Consolação, na cidade de São Paulo, Estado de São Paulo, por seus representantes legais, doravante denominada "ORGANIZADORA"; e
<br /><br />
O <strong>ARTISTA</strong>, devidamente identificado e qualificado através do login e senha de acesso ao sistema da <strong>ORGANIZADORA</strong>, doravante denominado "ARTISTA", sendo ARTISTA e ORGANIZADORA individualmente denominados "PARTE" e coletivamente "PARTES";
<br /><br />
CONSIDERANDO QUE:
<br /><br />
I – A ORGANIZADORA é organizadora oficial do evento de cultura pop, jogos, quadrinhos, filmes, séries de TV e colecionáveis, denominado "<strong>CCXP WORLDS</strong>”, a ser realizado de forma virtual/on-line, em plataforma de internet disponível a partir do endereço www.ccxp.com.br (doravante a “PLATAFORMA”), no período de 04 a 06 de dezembro de 2021  (doravante o “EVENTO”), cabendo-lhe com exclusividade a comercialização dos espaços virtuais de participação e cotas de patrocínio do EVENTO; e
<br /><br />
II – O ARTISTA inscreveu-se em processo de seleção para participar do EVENTO e, tendo sido selecionado pela ORGANIZADORA, confirma seu interesse em participar do ARTISTS’ VALLEY do EVENTO expondo seu trabalho em uma página específica da PLATAFORMA, com informações básicas acerca de seus trabalhos/produtos que lhe permite, ainda, direcionar o público visitante para seu site/e-commerce/redes sociais/canais próprios etc, conforme for de seu interesse, visando a exposição, divulgação e comercialização de seus trabalhos/produtos, concordando em submeter-se às regras específicas fixadas pela ORGANIZADORA para a sua participação no EVENTO;
<br /><br />
Fica justo e acordado entre as PARTES o presente Contrato de Participação na CCXP WORLDS 2021 (doravante o “CONTRATO”), que se regerá pelas seguintes cláusulas e condições:
<br /><br />
<strong>CLÁUSULA PRIMEIRA – OBJETO</strong>
<br /><br />
1.1.    Constitui objeto do presente CONTRATO a participação do ARTISTA no EVENTO, que se dará pela disponibilização de uma página exclusiva para o ARTISTA na <strong>área denominada ARTIST’S VALLEY</strong> da PLATAFORMA do EVENTO, composta de descritivos de atividades, vitrine de trabalho/produtos e outras soluções (sendo tal página exclusiva doravante denominada “MESA VIRTUAL”).
<br /><br />
1.1.1 Em sua MESA VIRTUAL o ARTISTA poderá, ainda, incluir um link para direcionar o público visitante, como melhor lhe convier, para seu site/e-commerce/hotsite/redes sociais e/ou canais próprios, que serão externos à PLATAFORMA e sem qualquer integração técnica a ela (sendo tal(is) espaço(s) próprio(s), externo(s) à PLATAFORMA, doravante denominado(s) em conjunto ou isoladamente, simplesmente “SITE EXTERNO”).
<br /><br />
1.2.    A MESA VIRTUAL e o SITE EXTERNO deverão ser utilizados pelo ARTISTA exclusivamente para:
<br /><br />
a) exposição e comercialização de livros e quadrinhos em seus diversos formatos criados e comercializados pelo próprio ARTISTA, além de itens derivados como prints, sketchbooks, artes originais etc, conforme tenham sido devidamente informados pelo ARTISTA durante o processo de seleção promovido pela ORGANIZADORA; e
<br /><br />
b) veiculação de conteúdos audiovisuais (vídeos, entrevistas, lives etc) que estejam devidamente autorizados pelos titulares dos direitos autorais e de personalidade cabíveis e que não constituam plágio, e realização de atividades e outras ações, à sua escolha e sob sua exclusiva responsabilidade, que deverão ter conexão com o objeto do EVENTO, ou seja, deverão preferencialmente focar em temas relacionados à cultura pop, quadrinhos, filmes, livros, games, música, séries de TV, vestuário e colecionáveis relacionados ao universo da cultura pop.
<br /><br />
1.3.    É expressamente proibido ao ARTISTA:
<br /><br />
a)  Solicitar aos visitantes no momento do acesso inicial ao SITE EXTERNO, o preenchimento de qualquer forma de cadastro eletrônico/fornecimento de dados pessoais, incluindo e-mail, mesmo que tal preenchimento seja opcional, podendo tais dados serem coletados em outros momentos da navegação, que não o acesso inicial, observando as regras aqui estabelecidas e a legislação vigente
<br /><br />
b)  Dividir a MESA VIRTUAL, sob qualquer forma, com outros artistas, marcas ou quaisquer terceiros, bem como nela expor - especialmente nos conteúdos e ativações que vier a desenvolver especialmente para o EVENTO - as marcas/produtos de terceiros e/ou realizar ações de promoção de marcas/produtos de terceiros sem a prévia e expressa aprovação da ORGANIZADORA, que poderá negá-la, a seu exclusivo critério, independentemente de justificativa;
<br /><br />
c)  Comercializar, expor, distribuir gratuitamente ou de qualquer forma estimular o consumo de qualquer tipo de bebida alcóolica ou substâncias ilícitas no SITE EXTERNO e/ou na MESA VIRTUAL;
<br /><br />
d)  Expor ou divulgar na MESA VIRTUAL e/ou no SITE EXTERNO, de qualquer forma, qualquer feira, exposição ou evento público que tenha objeto semelhante ao EVENTO, ou seja, que trate de cultura pop, jogos, quadrinhos, filmes, séries de TV e colecionáveis;
<br /><br />
e)  Expor na MESA VIRTUAL e/ou no SITE EXTERNO ou promover durante o EVENTO, de qualquer forma, qualquer objeto, material, conteúdo ou atividade que possa potencialmente configurar ou incentivar discriminação ou preconceito, de qualquer natureza, incluindo, sem limitação, discriminação em virtude de sexo, raça, cor, etnia, religião, procedência ou sexualidade, bem como material político e religioso;
<br /><br />
f)  Expor ou divulgar na MESA VIRTUAL e/ou no SITE EXTERNO, de qualquer forma, qualquer objeto, material, conteúdo ou atividade que seja de classificação etária “para maiores de 18 anos” (incluindo mas não se limitando à nudez, sexo, violência, incentivo/consumo de bebidas alcoólicas etc), salvo mediante prévia aprovação da ORGANIZADORA. Caso o ARTISTA pretenda divulgar qualquer conteúdo sujeito à classificação etária, deverá promover as devidas sinalizações/segmentações e restrições de acesso à tal conteúdo, nos termos da legislação vigente;
<br /><br />
g)  Expor e/ou comercializar na MESA VIRTUAL e/ou no SITE EXTERNO qualquer produto/serviço seu ou de terceiro em violação às leis vigentes, em todos os aspectos, incluindo autorizações necessárias ao seu uso/exposição e/ou comercialização, relacionadas à direitos autorais (“plágio”), de imagem, de representação/venda (“produto pirata”), fiscais, tributários, classificação etária ou outros;
<br /><br />
1.3.1 Caso a ORGANIZADORA verifique a infringência, pelo ARTISTA, de qualquer das proibições relacionadas nesta Cláusula, durante a realização do EVENTO, a ORGANIZADORA deverá notificar imediatamente o ARTISTA e este terá o prazo de 2 (duas) horas contadas do recebimento da referida notificação para cessar a prática irregular e/ou retirar da MESA VIRTUAL e/ou do SITE EXTERNO todo e qualquer produto/serviço à ela relacionado.
<br /><br />
1.3.2 A notificação de que trata a Cláusula 1.3.1 será feita por escrito, ao ARTISTA, na pessoa do responsável pelo MESA VIRTUAL que tenha sido assim cadastrado na PLATAFORMA.
<br /><br />
1.3.3 Caso o ARTISTA não cesse a prática irregular no prazo referido na Cláusula 1.3.1 acima, a ORGANIZADORA poderá, a seu exclusivo critério, determinar o bloqueio do acesso do ARTISTA ou dos frequentadores do EVENTO à MESA VIRTUAL de forma definitiva.
<br /><br />
1.4.    O ARTISTA poderá fazer ampla e irrestrita divulgação pública de sua participação no EVENTO, sendo-lhe desde já autorizada a utilização das marcas/logotipos associados ao EVENTO em seu material publicitário e de divulgação.
<br /><br />
1.5.  Toda e qualquer coleta e tratamento de dados do público realizada pelo ARTISTA no seu SITE EXTERNO deverá seguir estritamente o quanto disposto na Lei Geral de Proteção de Dados (Lei 13.709/18), cabendo ao ARTISTA a elaboração de política de privacidade, obtenção de autorização específica, e demais controles e responsabilidades constantes da referida lei, não sendo a ORGANIZADORA responsável por qualquer evento ou incidente de segurança relacionada a tal coleta e tratamento.
<br /><br />
1.6 Toda e qualquer captação de imagem realizada pelo ARTISTA no SITE EXTERNO, que envolva o público ou qualquer terceiro, deverá ser objeto de autorização específica obtida diretamente pelo ARTISTA perante o titular do correspondente direito de imagem/dados/voz, não lhe sendo autorizada, por este CONTRATO, a utilização de qualquer imagem, de qualquer terceiro, para quaisquer fins.
<br /><br />
1.7. O ARTISTA desde logo autoriza a ORGANIZADORA a utilizar seu nome, imagem, dados, marca/logotipos e qualquer outra referência ao ARTISTA e/ou aos seus trabalho/produtos e serviços, para fins de divulgação de sua participação no EVENTO, antes e após a realização deste.
<br /><br />
<strong>CLÁUSULA SEGUNDA – DO PRAZO</strong>
<br /><br />
2.1.    O EVENTO será realizado entre os dias 04 e 06 de dezembro de 2021, período no qual todas as funcionalidades da PLATAFORMA, incluindo a MESA VIRTUAL do ARTISTA, estarão disponíveis para visitação do público, bem como serão disponibilizados os conteúdos inéditos e realizadas atividades diversas pela ORGANIZADORA para o público (painéis, entrevistas, masterclasses, presença de artistas convidados etc).
<br /><br />
2.2 A PLATAFORMA estará disponível para que o ARTISTA realize seu cadastro, preencha as informações da sua MESA VIRTUAL e aponte o endereço de seu SITE EXTERNO a partir da data definida no MANUAL DO ARTIST’S VALLEY – ANEXO I deste CONTRATO.
<br /><br />
2.3 Eventualmente a PLATAFORMA poderá ficar disponível para visitação do público após o dia 06 de Dezembro de 2021, por prazo ainda não definido, com a disponibilização de alguns conteúdos da ORGANIZADORA em forma de acervo, conforme venham a ser autorizados pelos titulares de tais conteúdos. Em se concretizando tal possibilidade, a ORGANIZADORA comunicará tal fato ao ARTISTA, no prazo de até 10 (dez) dias antes da data de início do EVENTO, e este poderá, se assim desejar, manter sua MESA VIRTUAL e/ou SITE EXTERNO disponíveis pelo prazo adicional que vier a ser definido.
<br /><br />
2.3.1 Em qualquer hipótese, o ARTISTA deverá desenvolver interações, atividades ao vivo com os visitantes, realizar painéis, promoções e disponibilizar conteúdos inéditos em seu SITE EXTERNO apenas no período de 04 a 06 de Dezembro de 2021, usando eventual período adicional de disponibilidade da PLATAFORMA apenas para disponibilizar conteúdos em forma de acervo. A comercialização de produtos e/ou serviços poderá ser realizada indistintamente, durante todo o período em que a PLATAFORMA vier a ficar disponível para o público.
 <br /><br />
<strong>CLÁUSULA TERCEIRA – GRATUIDADE </strong>
<br /><br />
3.1.    A participação do ARTISTA no EVENTO dar-se-á a título gratuito, não sendo devida qualquer remuneração à ORGANIZADORA, a qualquer título.
<br /><br />
<strong>CLÁUSULA QUARTA – OBRIGAÇÕES DA ORGANIZADORA:</strong>
<br /><br />
4.1. A ORGANIZADORA se obriga a:
<br /><br />
a)  Disponibilizar a PLATAFORMA com a MESA VIRTUAL do ARTISTA durante o prazo definido na Cláusula 2.1, em regulares condições de uso, observado o disposto na Cláusula 4.2 abaixo;
<br /><br />
b)  Manter à disposição do ARTISTA uma equipe técnica para receber eventuais queixas sobre o funcionamento da PLATAFORMA e direcionar as correções necessárias;
<br /><br />
c)  Prestar todas as informações necessárias à correta utilização da MESA VIRTUAL pelo ARTISTA.
<br /><br />
d)  Assumir total e integral responsabilidade por:
<br /><br />
i.  quaisquer direitos e/ou reclamações que venham a ocorrer por parte de (i) entidades públicas e/ou privadas, em relação às suas responsabilidades para com as mesmas; (ii) fornecedores; e (iii) quaisquer pessoas envolvidas, direta ou indiretamente com o EVENTO e/ou relacionados à sua divulgação, promoção e realização, cuja contratação esteja sob sua responsabilidade;
<br /><br />
ii. pelo conteúdo, matérias, imagens, artigos e informações veiculadas por ela durante o EVENTO e/ou nos materiais publicitários e comerciais para a divulgação do EVENTO, cuja contratação esteja sob sua exclusiva responsabilidade;
<br /><br />
4.2 A PLATAFORMA em que se realizará o EVENTO é disponibilizada pela ORGANIZADORA ao ARTISTA “NO ESTADO EM QUE SE ENCONTRA”. A ORGANIZADORA não oferece nenhuma garantia de funcionamento ou de condições especificas de funcionamento e conexão da PLATAFORMA, tanto para o ARTISTA quanto para os visitantes do EVENTO.
<br /><br />
4.2.1 A PLATAFORMA pode não estar livre de interrupções ou erros, e NENHUMA FALHA/DEFEITO/INTERRUPÇÃO DE QUALQUER NATUREZA VERIFICADA NA PLATAFORMA OU NO MESA VIRTUAL DURANTE A REALIZAÇÃO DO EVENTO, DARÁ DIREITO A QUALQUER TIPO DE INDENIZAÇÃO, DE QUALQUER NATUREZA, AO ARTISTA, POR DANOS DIRETOS, INDIRETOS, MORAIS E MATERIAIS CONTRA A ORGANIZADORA, SEJA A QUE TÍTULO FOR.
<br /><br />
4.3 Caberá com exclusividade ao ARTISTA o preenchimento de suas informações na MESA VIRTUAL e o desenvolvimento, construção e operação do seu SITE EXTERNO, conforme melhor lhe aprouver. O ARTISTA será integral e exclusivamente responsável por toda e qualquer atividade, de qualquer natureza, que vier a desenvolver na sua MESA VIRTUAL e no seu SITE EXTERNO, bem como por todo e qualquer trabalho/produto exposto/vendido em tais áreas, perante qualquer terceiro, sob todos os aspectos, devendo manter a ORGANIZADORA livre e indene de qualquer responsabilidade nesse sentido.
<br /><br />
<strong>CLÁUSULA QUINTA – OBRIGAÇÕES DO ARTISTA:</strong>
<br /><br />
5.1. O ARTISTA se obriga a:
<br /><br />
a)  Cumprir com todas as obrigações assumidas neste CONTRATO e em seus Anexos, especialmente no MANUAL DO ARTIST’S VALLEY, bem como observar todas as especificações e orientações que lhe forem razoavelmente fornecidas pela ORGANIZADORA;
<br /><br />
b)  Comunicar à ORGANIZADORA qualquer problema da PLATAFORMA imediatamente após a sua constatação;
<br /><br />
c)  Responder com exclusividade, perante os visitantes da sua MESA VIRTUAL e/ou SITE EXTERNO e quaisquer terceiros, por todo o conteúdo neles disponibilizado, em todos os seus aspectos, bem como por todas as vendas realizadas, incluindo o cumprimento das obrigações assumidas em relação ao prazo de entrega e devolução de produtos, nos termos do Código de Defesa do Consumidor, isentando a ORGANIZADORA de qualquer responsabilidade neste sentido;
<br /><br />
d)  Responder, com exclusividade, por toda e qualquer coleta e tratamento de dados do público realizada no seu SITE EXTERNO, de acordo com o quanto disposto na Lei Geral de Proteção de Dados (Lei 13.709/18), isentando e mantendo a ORGANIZADORA livre e indene de qualquer responsabilidade por eventos ou incidentes de segurança relacionados à tal coleta e tratamento;
<br /><br />
e)  Responder integral e exclusivamente por todo e qualquer trabalho/produto/serviço/conteúdo seu ou de terceiro que vier a expor, exibir e comercializar na MESA VIRTUAL e/ou SITE EXTERNO, no que diz respeito às leis vigentes, em todos os aspectos, incluindo autorizações necessárias ao seu uso/exposição/publicidade e/ou comercialização, relacionadas à direitos autorais, de imagem, de representação/venda, fiscais, tributários, classificação etária ou outros, isentando e mantendo a ORGANIZADORA livre e indene de qualquer obrigação ou responsabilidade nesse sentido;
<br /><br />
f)  Responder com exclusividade por quaisquer direitos e/ou reclamações que venham a ocorrer por parte de (i) entidades públicas e/ou privadas, em relação às suas responsabilidades para com as mesmas; (ii) fornecedores; e (iii) quaisquer pessoas envolvidas, direta ou indiretamente com a sua participação no EVENTO, isentando e mantendo a ORGANIZADORA livre e indene de qualquer obrigação ou responsabilidade nesse sentido.
<strong>CLÁUSULA SEXTA – PRAZO E RESCISÃO</strong>
<br /><br />
6.1.    O presente CONTRATO vigorará da data de sua assinatura até 20 de dezembro de 2021, ou até que todas as obrigações dele decorrentes sejam integralmente cumpridas, obrigando as PARTES e seus sucessores, a qualquer título.
<br /><br />
6.2. A PARTE que pretender a rescisão do presente CONTRATO deverá notificar a outra PARTE, por escrito e com comprovante de recebimento, nos endereços constantes do preâmbulo, operando-se a rescisão imediatamente após o recebimento da referida notificação, sem a incidência de qualquer penalidade, a qualquer título, inclusive perdas e/ou danos de qualquer natureza.
<br /><br />
<strong>CLÁUSULA SÉTIMA – DISPOSIÇÕES GERAIS</strong>
<br /><br />
7.1.    O não exercício, por qualquer das PARTES, dos direitos e faculdades a elas conferidos neste CONTRATO será considerado mera liberalidade, não importando em renúncia ao exercício futuro do direito ou faculdade, tampouco novação contratual.
<br /><br />
7.2.    Integram o presente CONTRATO, como se nele estivessem transcritos, o seguinte ANEXO, devidamente recebidos e validados pelo ARTISTA:
ANEXO I - MANUAL DO ARTIST’S VALLEY
<br /><br />
7.3.     Para dirimir quaisquer pendências ou dúvidas decorrentes deste CONTRATO as PARTES elegem o Foro da Cidade de São Paulo, Estado de São Paulo renunciando a qualquer outro por mais privilegiado que seja.
<br /><br />
<strong>Ao aceitar eletronicamente os termos do presente Contrato, o ARTISTA aceita, concorda e reconhece como válidas as condições contratuais ajustadas, a partir da data do referido aceite.</strong>
<br /><br /><br /><br />

<strong>CONTRACT PARTICIPATION CCXP WORLDS 2021</strong>
<br /><br />
By this present instrument and in the best terms of the law, between the parties:
<br /><br />
<strong>CCXP EVENTOS LTDA.</strong>, CNPJ/MF nº 19.641.956/0001-09, located at Rua Ceará, nº480 – room 11, Bairro Consolação, in the city of São Paulo, State of São Paulo, via its legal representatives, hereinafter named “ORGANIZER”; and
<br /><br />
<strong>THE ARTIST, duly identified</strong> and qualified by the <strong>login and password granting access to the ORGANIZER’s system</strong>, hereinafter named “ARTIST”, being that ARTIST and ORGANIZER may individually be named “PARTY” and collectively “PARTIES”;
<br /><br />
CONSIDERING THAT:
<br /><br />
I- The ORGANIZER is the oficial organizer of the pop culture, games, comic books, films, TV series and collectibles event named "<strong>CCXP WORLDS</strong>”, to happen in a virtual/online setting, on a internet platform available from the URL www.ccxp.com.br (hereinafter the “PLATFORM”), for the period 4th-6th December 2021 (hereinafter the “EVENT”), with the exclusive right to commercialize virtual spaces for participation and sponsorship shares of the EVENT; and
<br /><br />
II- The ARTIST signed into a selection process to take part in the EVENT and, having been successfully selected by the ORGANIZER, confirms their interest in participating in the EVENT’s ARTIST’S VALLEY, exhibiting their work on a specific page of the PLATFORM, with basic information regarding their work and products that will allow them to direct the visiting public to their website/e-commerce/social networks/own channels, as they prefer, with the intent of exhibition, promotion and commercialization of their work and products, agreeing to be subject to the specific rules the ORGANIZER requires for participation in the EVENT;
<br /><br />
The present CONTRACT PARTICIPATION CCXP WORLDS 2021 (hereinafter the “CONTRACT”) is considered just and agreed upon by the PARTIES, and the following clauses and conditions apply:
<br /><br />
<strong>FIRST CLAUSE - OBJECT</strong>
<br /><br />
1.1 The object of this CONTRACT is the participation of the ARTIST in the EVENT, that will be through the provision to the ARTIST of a exclusive webpage in the <strong>area named ARTIST’S VALLEY</strong> on the EVENT’s PLATFORM, composed of activity descriptions, virtual storefront for works and products, and other solutions (this exclusive webpage hereinafter named “VIRTUAL TABLE”).
<br /><br />
1.1.1 On their VIRTUAL TABLE the ARTIST may include a link to direct the visiting public, in the manner they prefer, to their website/e-commerce/hotsite/social networks and/or own channels, that shall be external to the PLATFORM and with no technical integration between them (hereinafter these artist’s own spaces, external to the PLATFORM, shall be named jointly or individually “EXTERNAL SITE”).
<br /><br />
1.2 The VIRTUAL TABLE and the EXTERNAL SITE must be used by the ARTIST exclusively for the:
<br /><br />
a)  exhibition and commercialization of books and comic books in their diverse formats created and commercialized by the own ARTIST, and other derivative items such as prints, sketchbooks, original artworks, etc., as duly described by the ARTIST during the selection process promoted by the ORGANIZER; and
<br /><br />
b)  placement of audiovisual content (videos, interviews, lives, etc.) that are duly authorized by the holder of the creator’s rights and proper entities and that do not constitute plagiarism, and the realization of activities and other actions, of the ARTIST’s choice and exclusive responsibility, that must be connected to the object of the EVENT, that is, they must preferably focus on themes related to pop culture, comic books, movies, books, games, music, TV series, clothing and collectibles related to the world of pop culture.
<br /><br />
1.3 It is expressly prohibited to the ARTIST:
<br /><br />
•   Solicit visitors at the point of first access to the EXTERNAL SITE to fill any kind of electronic form/personal information, including e-mail, even if it is optional, however there is the possibility of these informations being collected at other points in the navigation, excluding the initial access, observing the rules laid out in this document and standing legislation;
<br /><br />
•   To share the VIRTUAL TABLE, in any way, with other artists, brands or third parties, or expose - especially in the content and actions they may develop specifically for the EVENT - brands/products of third parties and/or actions to promote brands/products of third parties without previous and express approval of the ORGANIZER, whom may not grant approval, at their discretion, without need of justification;
<br /><br />
•   Commercialize, exhibit, distribute for free or in any way incentivise the consumption of any kind of alcoholic beverage or illicit substance on the EXTERNAL SITE and/or VIRTUAL TABLE;
<br /><br />
•   Exhibit or publicize on the VIRTUAL TABLE and/or EXTERNAL SITE, in any manner, any market, exhibition or public event that has a similar object to the EVENT, that is, that deals with pop culture, games, comic books, movies, TV series and collectibles;
<br /><br />
•   Exhibit on the VIRTUAL TABLE and/or EXTERNAL SITE or promote during the EVENT, in any form, any object, material, content or activity that may potentially constitute or be an incentive for discrimination or prejudice, of any nature, including, but not limited to, discrimination in virtue of gender, race, color, ethnicity, religion, origin or sexual orientation, and also political and religious materials;
<br /><br />
•   Exhibit or market on the VIRTUAL TABLE and/or EXTERNAL SITE, in any manner, any object, material, content or activity that is classified for ages “18 or older” (including, but not limited to, nudity, sex, violence, incentive/consumption of alcoholic beverages, etc.), unless with prior approval of the ORGANIZER. In case the ARTIST intends to market any classified content, they must provide the due signage/segmentation and restrictions to access such content, in the terms of the law;
<br /><br />
•   Exhibit and/or commercialize on the VIRTUAL TABLE and/or EXTERNAL SITE any product/service, be it their own or third party, that is in violation of  standing legislation, in any aspect, including necessary authorizations to use/exhibit and/or commercialize, related to creator’s rights (“plagiarism”), image rights, representation/sale (“pirated products”), fiscal, tax, age appropriate classification and others;
<br /><br />
1.3.1 In case the ORGANIZER encounters an infringement by the ARTIST of any of the prohibited items in this clause, throughout the duration of the EVENT, the ORGANIZER shall immediately notify the ARTIST and they will have a 2 (two) hour deadline, from the moment they receive said notification, to cease the irregular practice and/or remove from the VIRTUAL TABLE and/or EXTERNAL SITE any and all products/services related to the infringement.
<br /><br />
1.3.2 The notification mentioned in clause 1.3.1 will be in writing to the ARTIST, as person responsible for the VIRTUAL TABLE and as such registered on the PLATFORM.
<br /><br />
1.3.3 In case the ARTIST does not desist the irregular practice in the deadline mentioned in clause 1.3.1, the ORGANIZER may, at their discretion, determine the ARTIST’s access and/or the EVENT’s visitors access to their VIRTUAL TABLE be blocked definitely.
<br /><br />
1.4 The ARTIST may amply, unreservedly and publicly announce their participation in the EVENT, and from this moment is authorized to use the EVENT brand logo and design elements associated with the EVENT in their advertising and marketing material.
<br /><br />
1.5 All and every collection data from the public by the ARTIST via the EXTERNAL SITE must strictly follow the Brazilian General Data Protection Law (Lei 13.709/18), being the ARTIST responsible for elaboration of the privacy policy, obtention of specific authorization, and further controls and responsibilities laid out in said law, and the ORGANIZER is not responsible for any security event or incident related to said collection and treatment.
<br /><br />
1.6 All and any images collected by the ARTIST via the EXTERNAL SITE, that involves the public or any third parties, must be subject to specific authorization obtained directly by the ARTIST with the holder of the direct correspondent image/data/voice, not being authorized in this CONTRACT the use of any image of third parties under any circumstance.
<br /><br />
1.7 The ARTIST immediately authorizes the ORGANIZER to use their name, image, data, brand/logo and any other reference to the ARTIST and/or their work/products and services, for purposes of promoting their participation in the EVENT, both before and after it takes place.
<br /><br />
<strong>SECOND CLAUSE - TIMETABLE</strong>
<br /><br />
2.1 The EVENT will happen on the 4th, 5th and 6th December 2021, during which all the PLATFORM’s functionalities, including the ARTIST’s VIRTUAL TABLE, will be available for public visitation, as will the original content and various activities carried out by the ORGANIZER for the public (panels, interviews, masterclasses, with invited artists, etc.).
<br /><br />
2.2 The PLATFORM will be available for the ARTIST to register, fill out information of their VIRTUAL TABLE and include the address of their EXTERNAL SITE from the date specified in the ARTIST’S VALLEY MANUAL – ANNEX I of this CONTRACT
<br /><br />
2.3 Possibly the PLATFORM might be available to public visitation after 6th December 2021, for an undefined amount of time, with the availability of some of the ORGANIZER’s content in archival form, as they are authorized by the titular rights holder’s of such content. If this comes to fruition, the ORGANIZER will communicate such fact to the ARTIST, with the deadline of up to 10 (ten) days before the beginning of the EVENT, and they can, if they wish, keep their VIRTUAL TABLE and/or EXTERNAL SITE available for the additional dates yet to be defined.
<br /><br />
2.3.1 In any case, the ARTIST must develop interactions, live activities with their visitors, panels, promotions and provide original and never seen before content on their EXTERNAL SITE only for the period of 4th to 6th December 2021, using eventual additional period of availability of the PLATFORM exclusively to provide content in archival form. The commercialization of products and/or services may be performed indistinctly, at all times the PLATFORM is available to the public.
<br /><br />
<strong>THIRD CLAUSE - GRATUITY</strong>
<br /><br />
3.1 The participation of the ARTIST in the EVENT will be free of charge, with no amount of payment owed to the ORGANIZER, in any designation.
<br /><br />
FOURTH CLAUSE - ORGANIZER’S OBLIGATIONS
<br /><br />
4.1 The ORGANIZER will:
<br /><br />
•   Make the PLATFORM with the ARTIST’s VIRTUAL TABLE available during the period defined in Clause 2.1, in good conditions of use, observing the reservations in Clause 4.2;
<br /><br />
•   Have an IT department available for the ARTIST, to receive eventual complaints about the PLATFORM and direct necessary corrections;
<br /><br />
•   Give all necessary information for the correct use of the VIRTUAL TABLE by the ARTIST;
<br /><br />
•   Take full and complete responsibility for:
<br /><br />
•   any rights and/or complaints that may occur on behalf of (i) public and/or private entities, in relation to its responsibilities towards these entities; (ii) suppliers; and (iii) any persons involved directly or indirectly with the EVENT and/or related to its marketing, promotion and realization, when their hiring is the ORGANIZER’s responsibility;
<br /><br />
•   the content, articles, images, reports and information aired by the ORGANIZER during the EVENT and/or in advertising copy and commercials for the EVENT’s promotion, when their hiring is the ORGANIZER’s responsibility;
<br /><br />
4.2 The PLATFORM on which the EVENT will be realized is made available by the ORGANIZER to the ARTIST “AS IS.” The ORGANIZER does not offer any guarantee of working conditions or specific circumstances of connection and working conditions of the PLATFORM, both for the ARTIST and the EVENT’s visiting public.
<br /><br />
4.2.1 The PLATFORM may not be unencumbered of interruptions or errors, and NO FAILURE/DEFECT/INTERRUPTION OF ANY NATURE FOUND ON THE PLATFORM OR ON THE VIRTUAL TABLE DURING THE REALIZATION OF THE EVENT, WILL GIVE ANY RIGHT OF RESTITUTION, OF ANY NATURE, TO THE ARTIST FOR DIRECT, INDIRECT, MORAL OR MATERIAL DAMAGES, AGAINST THE ORGANIZER, UNDER ANY CIRCUMSTANCE.
<br /><br />
4.3 It will be the sole responsibility of the ARTIST to fill the informations on the VIRTUAL TABLE and the development, construction and operation of their EXTERNAL SITE, it whichever way they see fit. The ARTIST will be fully and exclusively responsible for all and any activity, of any nature, that they develop on their VIRTUAL TABLE and EXTERNAL SITE, as well as for all and any work/product shown/sold on such areas, towards any third party, in any condition, holding the ORGANIZER exempt and harmless of legal responsibility for such.
<br /><br />
<strong>FIFTH CLAUSE - ARTIST’S OBLIGATIONS</strong>
<br /><br />
5.1 The ARTIST commits to:
<br /><br />
•   Fulfil all obligations laid out in this CONTRACT and its Annexes, specially the ARTIST’S VALLEY MANUAL, as well as observing all specifications and orientations that are reasonably given by the ORGANIZER;
<br /><br />
•   Communicate to the ORGANIZER any problem with the PLATFORM as soon as it is found;
<br /><br />
•   Be exclusively responsible towards visitors on their VIRTUAL TABLE and/or EXTERNAL SITE and third parties for all content available on them, in all aspects, as well as all sales performed, including the obligations undertaken related to delivery deadlines and product returns, in the terms of Brazilian law (Código de Defesa do Consumidor/Consumer Defense Code), exempting the ORGANIZER of any responsibility for such;
<br /><br />
•   Be exclusively responsible for all and any collection and treatment of data from the public operated by their EXTERNAL SITE, in the terms of Brazilian law (Lei Geral de Proteção de Dados/General Data Protection Law - Lei 13.709/18) exempting and holding the ORGANIZER free and harmless of legal responsibility for events or security incidents related to such collection and treatment;
<br /><br />
•   Be fully and exclusively responsible for all and any work/product/service/content, theirs or third party, that they may exhibit, market and commercialize on their VIRTUAL TABLE and/or EXTERNAL SITE, in relation to applicable law, in all aspects, including necessary authorizations for its use/exhibition/marketing and/or commercialization, related to creator’s rights, image rights, representation/sales, fiscal, taxes, age-appropriate classification and others, exempting and holding the ORGANIZER free and harmless of any obligation or legal responsibility for such;
<br /><br />
•   Be exclusively responsible for all and any rights and/or complaints that may occur on behalf of (i) public and/or private entities, in relation to their responsibilities towards these entities; (ii) suppliers; and (iii) any persons involved directly or indirectly with the EVENT, exempting and holding the ORGANIZER free and harmless of any obligation or legal responsibility for such.
<br /><br />
<strong>SIXTH CLAUSE - DURATION AND TERMINATION</strong>
<br /><br />
6.1 This CONTRACT is valid from the date of signature until 20th December 2021, or until all obligations contained within are totally fulfilled, binding all PARTIES and their successors, under any circumstance.
<br /><br />
6.2 The PARTY that wants the termination of this CONTRACT must notify the other PARTY, in writing and with proof of receipt, at the addresses contained in the preambule, with the termination entering in effect immediately after the receipt of such notification, without incurring in any penalty, under any circumstance, including losses and/or damages of any kind.
<br /><br />
<strong>SEVENTH CLAUSE - GENERAL PROVISIONS</strong>
<br /><br />
7.1 The non-use, by either PARTY, of the rights and choices conferred by this CONTRACT will be considered a mere liberality, not implying in void future use of these rights and choices, or a new contract.
<br /><br />
7.2 The following ANNEX is part of this CONTRACT, as if transcribed herewithin, duly received and validated by the ARTIST:
ANNEX I - ARTIST’S VALLEY MANUAL
<br /><br />
7.3 To settle any pendency or dispute related to this CONTRACT the PARTIES agree upon the Courts of the Judicial District of São Paulo, State of São Paulo, and exclude any other court that may be claimed, regardless of entitlement.
<br /><br />
<strong>Upon electronically accepting the terms of this CONTRACT, the ARTIST accepts, agrees and recognizes as valid all contractual adjustment conditions, from the date of such acceptance.</strong>`,

    experiencesAgreement: `<strong>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA CCXP WORLDS 2021</strong>
<br /><br />
Ao acessar a <strong>PLATAFORMA CCXP WORLDS 2021</strong> (“PLATAFORMA”), disponibilizada pela CCXP EVENTOS LTDA. (“<strong>ORGANIZADORA</strong>”) ao PARTICIPANTE/ARTISTA, com a exclusiva finalidade de permitir ao PARTICIPANTE/ARTISTA participar do evento denominado CCXP WORLDS 2021 (“<strong>EVENTO</strong>”), o PARTICIPANTE/ARTISTA, fica ciente e aceita que:
<br /><br />
1. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE, são disponibilizados pela ORGANIZADORA ao PARTICIPANTE/ARTISTA “<strong>NO ESTADO EM QUE SE ENCONTRA</strong>”. A ORGANIZADORA não oferece nenhuma garantia de funcionamento ou de condições especificas de funcionamento e conexão da PLATAFORMA e/ou do ESPAÇO/MESA VIRUTAL, tanto para o PARTICIPANTE/ARTISTA quanto para os visitantes do EVENTO.
<br /><br />
2. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE/ARTISTA podem não estar livres de interrupções ou erros, e <strong>NENHUMA FALHA/DEFEITO/INTERRUPÇÃO DE QUALQUER NATUREZA VERIFICADA NA PLATAFORMA E/OU NO ESPAÇO/MESA VIRTUAL DURANTE A REALIZAÇÃO DO EVENTO, DARÁ DIREITO A QUALQUER TIPO DE INDENIZAÇÃO, DE QUALQUER NATUREZA, AO PARTICIPANTE/ARTISTA, POR DANOS DIRETOS, INDIRETOS, MORAIS E MATERIAIS CONTRA A ORGANIZADORA, SEJA A QUE TÍTULO FOR</strong>.
<br /><br />
3. É de exclusiva responsabilidade do PARTICIPANTE/ARTISTA o preenchimento de suas informações no ESPAÇO/MESA VIRTUAL, e a inclusão dos “links” seu site/e-commerce/hotsite/redes sociais e/ou canais próprios a que o PARTICIPANTE/ARTISTA vier a direcionar os frequentadores do EVENTO (em conjunto ou isoladamente, “SITE EXTERNO”). O SITE EXTERNO não possui qualquer integração técnica à PLATAFORMA, devendo ser meramente informado no ESPAÇO/MESA VIRTUAL através do apontamento do link correspondente.
<br /><br />
4. É de <strong>exclusiva responsabilidade do PARTICIPANTE/ARTISTA</strong> o desenvolvimento, construção e operação do seu SITE EXTERNO, conforme melhor lhe aprouver. O PARTICIPANTE/ARTISTA será integral e exclusivamente responsável por toda e qualquer atividade e conteúdo, de qualquer natureza, que vier a desenvolver/veicular no seu ESPAÇO/MESA VIRTUAL e no seu SITE EXTERNO, bem como por todo e qualquer trabalho ou produto exposto/vendido em tais áreas, perante qualquer terceiro, sob todos os aspectos legais, - incluindo, mas não se limitando, às autorizações necessárias ao seu uso/exposição/publicidade/veiculação/transmissão e/ou comercialização, relacionadas à direitos autorais (plágio/pirataria), de imagem, de representação/venda, fiscais, tributários, classificação etária ou outros -, devendo manter a ORGANIZADORA livre e indene de qualquer responsabilidade em relação ao seu ESPAÇO/MESA VIRTUAL e SITE EXTERNO.
<br /><br />
5. O <strong>PARTICIPANTE/ARTISTA deverá responder, com exclusividade</strong>, por toda e qualquer coleta e tratamento de dados do público realizada no seu SITE EXTERNO, de acordo com o quanto disposto na <strong>Lei Geral de Proteção de Dados (Lei 13.709/18)</strong>, isentando  e mantendo a ORGANIZADORA livre e indene de qualquer responsabilidade por eventos ou incidentes de segurança relacionados à tal coleta e tratamento.
<br /><br />
6. <strong>Aplicam-se, ainda, de forma complementar a estes Termos e Condições de Uso da Plataforma CCXP Worlds 2021, todos os termos e condições estabelecidos no CONTRATO DE PARTICIPAÇÃO NA CCXP WORLDS</strong> celebrado entre o PARTICIPANTE/ARTISTA e a ORGANIZADORA, como se nele estivessem transcritos, integralmente, incluindo suas definições, assim como em seus <strong>ANEXOS e nos MANUAIS de participação na CCXP WORLDS e orientações gerais que foram disponibilizados pela ORGANIZADORA</strong> para o PARTICIPANTE/ARTISTA. O PARTICIPANTE/ARTISTA deverá observar todas as regras estabelecidas em tais documentos, sem exceção, sob pena de incorrer em descumprimento contratual, podendo sofrer as consequências previstas em tais documentos.
<strong>O PARTICIPANTE/ARTISTA declara expressamente que leu, compreendeu e aceitou estes Termos e Condições e os demais documentos mencionados no item 6 acima, e a Política de Privacidade e que está totalmente ciente dos direitos e obrigações que deles emanam.</strong>
<br /><br /><br />
<strong>CCXP WORLDS 2021 PLATFORM – TERMS OF USE</strong>
<br /><br />
By accessing <strong>CCXP WORLDS 2021’s WEBSITE</strong> (hereinafter referred to as “<strong>PLATFORM</strong>”) as made available by CCXP EVENTOS LTDA. (hereinafter referred to as “ORGANIZER”) to the PARTICIPANT/ARTIST with the sole purpose of allowing the aforementioned PARTICIPANT/ARTIST to participate in the event entitled CCXP WORLDS 2021 (hereinafter referred to as “<strong>EVENT</strong>”), the PARTICIPANT/ARTIST is aware and accepts that:
<br /><br />
1. THE PLATFORM as well as the PARTICIPANT'S AREA/DIGITAL BOOTH are made available by the ORGANIZER to the PARTICIPANT/ARTIST on a "AS IS" basis. The ORGANIZER does not offer any guarantees regarding operation nor any specific conditions of operation and internet connection regarding the PLATFORM and/or DIGITAL BOOTH/PARTICIPANT’S AREA, be it for the PARTICIPANT/ARTIST or the visitors of the EVENT.
<br /><br />
2. We would like to make clear that THE PLATFORM, as well as the PARTICIPANT’S AREA/ DIGITAL BOOTH, are not free of any interruptions or errors, and <strong>NO FAILURE/DEFECT/INTERRUPTION OF ANY NATURE RELATED TO THE PLATFORM AND/OR IN THE PARTICIPANT’S AREA/DIGITAL BOOTH DURING THE EVENT PROVIDE LEGAL BASIS TO ANY KIND OF LAWSUIT RESULTING IN INDEMNITY OF ANY NATURE TO THE PARTICIPANT/ARTIST FOR DIRECT, INDIRECT MORAL AND MATERIAL DAMAGES DONE AGAINST THE ORGANIZER REGARDLESS OF THE PURPOSES</strong>
<br /><br />
3. It is the PARTICIPANT/ARTIST entire responsibility to submit all required information to their AREA/DIGITAL BOOTH as well as the entering any links redirecting to their websites/stores/hotsites/social network profiles, and/or their own video platforms (“EXTERNAL WEBSITES”) that the PARTICIPANT/ARTIST wishes present to any EVENT VISITORS. These EXTERNAL WEBSITES do not present any technical integration with the event PLATFORM and should be informed in the PARTICIPANT’S AREA/DIGITAL BOOTH only for the purpose of redirecting users to an external website.
<br /><br />
4. It is <strong>the PARTICIPANT/ARTIST sole responsibility</strong> to handle the design, development, and operation of any EXTERNAL WEBSITES. The PARTICIPANT/ARTIST are also solely responsible for all activities and contents developed/displayed on his/her PARTICIPANT’S AREA/DIGITAL BOOTH and on his EXTERNAL WEBSITE as well as for any and all work or product displayed/sold in such areas regardless of their nature under all legal aspects and including, but not limited to, authorizations granted for such use/exposure/advertising/placement/livestreaming, and/or selling related to copyright laws in effect (regarding plagiarism/piracy ), use of image, sale/commercial representation, taxes, age rating or others. Also, the ORGANIZER will remain exempt of any liability regarding a PARTICIPANT’S AREA/DIGITAL BOOTH and EXTERNAL WEBSITES.
<br /><br />
5. It is also the <strong>PARTICIPANTS/ARTISTS sole responsibility</strong> to legally deal with any and all kinds of public data gathering and processing carried out on using an EXTERNAL WEBSITE in compliance with the Data Protection Law (13.709/18), thus exempting the ORGANIZER of any liability for any events or security incidents related to such data.
<br /><br />
6. <strong>All the terms and conditions in this agreement was entered into between the parties, the PARTICIPANT/ARTIST and the ORGANIZER, in a complementary manner to all terms and conditions established by CCXP WORLDS 2021 ATTENDANCE AGREEMENT</strong> as if fully written therein and including all relevant definitions, as well as in their <strong>ANNEXES and in the MANUALS of participation in CCXP WORLDS and general guidelines that were made available by the ORGANIZER for the PARTICIPANT/ARTIST</strong>. The PARTICIPANT/ARTIST must make observe regulations described in these documents without exception on pain of the imposition of a penalty based on consequences provided in such documents for what is considered a breach of contract.
<strong>The PARTICIPANT/ARTIST formally expresses the statement of agreement that he has read, understood and accepted these Terms and Conditions as well as any other conditions established on the other documents mentioned in item 6 and the Privacy Policy, stating that he/she is fully aware of the rights and obligations arising from them.</strong>`,

    studiosAgreement: `<strong>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA CCXP WORLDS 2021</strong>
<br /><br />
Ao acessar a <strong>PLATAFORMA CCXP WORLDS 2021</strong> (“PLATAFORMA”), disponibilizada pela CCXP EVENTOS LTDA. (“<strong>ORGANIZADORA</strong>”) ao PARTICIPANTE/ARTISTA, com a exclusiva finalidade de permitir ao PARTICIPANTE/ARTISTA participar do evento denominado CCXP WORLDS 2021 (“<strong>EVENTO</strong>”), o PARTICIPANTE/ARTISTA, fica ciente e aceita que:
<br /><br />
1. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE, são disponibilizados pela ORGANIZADORA ao PARTICIPANTE/ARTISTA “<strong>NO ESTADO EM QUE SE ENCONTRA</strong>”. A ORGANIZADORA não oferece nenhuma garantia de funcionamento ou de condições especificas de funcionamento e conexão da PLATAFORMA e/ou do ESPAÇO/MESA VIRUTAL, tanto para o PARTICIPANTE/ARTISTA quanto para os visitantes do EVENTO.
<br /><br />
2. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE/ARTISTA podem não estar livres de interrupções ou erros, e <strong>NENHUMA FALHA/DEFEITO/INTERRUPÇÃO DE QUALQUER NATUREZA VERIFICADA NA PLATAFORMA E/OU NO ESPAÇO/MESA VIRTUAL DURANTE A REALIZAÇÃO DO EVENTO, DARÁ DIREITO A QUALQUER TIPO DE INDENIZAÇÃO, DE QUALQUER NATUREZA, AO PARTICIPANTE/ARTISTA, POR DANOS DIRETOS, INDIRETOS, MORAIS E MATERIAIS CONTRA A ORGANIZADORA, SEJA A QUE TÍTULO FOR</strong>.
<br /><br />
3. É de exclusiva responsabilidade do PARTICIPANTE/ARTISTA o preenchimento de suas informações no ESPAÇO/MESA VIRTUAL, e a inclusão dos “links” seu site/e-commerce/hotsite/redes sociais e/ou canais próprios a que o PARTICIPANTE/ARTISTA vier a direcionar os frequentadores do EVENTO (em conjunto ou isoladamente, “SITE EXTERNO”). O SITE EXTERNO não possui qualquer integração técnica à PLATAFORMA, devendo ser meramente informado no ESPAÇO/MESA VIRTUAL através do apontamento do link correspondente.
<br /><br />
4. É de <strong>exclusiva responsabilidade do PARTICIPANTE/ARTISTA</strong> o desenvolvimento, construção e operação do seu SITE EXTERNO, conforme melhor lhe aprouver. O PARTICIPANTE/ARTISTA será integral e exclusivamente responsável por toda e qualquer atividade e conteúdo, de qualquer natureza, que vier a desenvolver/veicular no seu ESPAÇO/MESA VIRTUAL e no seu SITE EXTERNO, bem como por todo e qualquer trabalho ou produto exposto/vendido em tais áreas, perante qualquer terceiro, sob todos os aspectos legais, - incluindo, mas não se limitando, às autorizações necessárias ao seu uso/exposição/publicidade/veiculação/transmissão e/ou comercialização, relacionadas à direitos autorais (plágio/pirataria), de imagem, de representação/venda, fiscais, tributários, classificação etária ou outros -, devendo manter a ORGANIZADORA livre e indene de qualquer responsabilidade em relação ao seu ESPAÇO/MESA VIRTUAL e SITE EXTERNO.
<br /><br />
5. O <strong>PARTICIPANTE/ARTISTA deverá responder, com exclusividade</strong>, por toda e qualquer coleta e tratamento de dados do público realizada no seu SITE EXTERNO, de acordo com o quanto disposto na <strong>Lei Geral de Proteção de Dados (Lei 13.709/18)</strong>, isentando  e mantendo a ORGANIZADORA livre e indene de qualquer responsabilidade por eventos ou incidentes de segurança relacionados à tal coleta e tratamento.
<br /><br />
6. <strong>Aplicam-se, ainda, de forma complementar a estes Termos e Condições de Uso da Plataforma CCXP Worlds 2021, todos os termos e condições estabelecidos no CONTRATO DE PARTICIPAÇÃO NA CCXP WORLDS</strong> celebrado entre o PARTICIPANTE/ARTISTA e a ORGANIZADORA, como se nele estivessem transcritos, integralmente, incluindo suas definições, assim como em seus <strong>ANEXOS e nos MANUAIS de participação na CCXP WORLDS e orientações gerais que foram disponibilizados pela ORGANIZADORA</strong> para o PARTICIPANTE/ARTISTA. O PARTICIPANTE/ARTISTA deverá observar todas as regras estabelecidas em tais documentos, sem exceção, sob pena de incorrer em descumprimento contratual, podendo sofrer as consequências previstas em tais documentos.
<strong>O PARTICIPANTE/ARTISTA declara expressamente que leu, compreendeu e aceitou estes Termos e Condições e os demais documentos mencionados no item 6 acima, e a Política de Privacidade e que está totalmente ciente dos direitos e obrigações que deles emanam.</strong>
<br /><br /><br />
<strong>CCXP WORLDS 2021 PLATFORM – TERMS OF USE</strong>
<br /><br />
By accessing <strong>CCXP WORLDS 2021’s WEBSITE</strong> (hereinafter referred to as “<strong>PLATFORM</strong>”) as made available by CCXP EVENTOS LTDA. (hereinafter referred to as “ORGANIZER”) to the PARTICIPANT/ARTIST with the sole purpose of allowing the aforementioned PARTICIPANT/ARTIST to participate in the event entitled CCXP WORLDS 2021 (hereinafter referred to as “<strong>EVENT</strong>”), the PARTICIPANT/ARTIST is aware and accepts that:
<br /><br />
1. THE PLATFORM as well as the PARTICIPANT'S AREA/DIGITAL BOOTH are made available by the ORGANIZER to the PARTICIPANT/ARTIST on a "AS IS" basis. The ORGANIZER does not offer any guarantees regarding operation nor any specific conditions of operation and internet connection regarding the PLATFORM and/or DIGITAL BOOTH/PARTICIPANT’S AREA, be it for the PARTICIPANT/ARTIST or the visitors of the EVENT.
<br /><br />
2. We would like to make clear that THE PLATFORM, as well as the PARTICIPANT’S AREA/ DIGITAL BOOTH, are not free of any interruptions or errors, and <strong>NO FAILURE/DEFECT/INTERRUPTION OF ANY NATURE RELATED TO THE PLATFORM AND/OR IN THE PARTICIPANT’S AREA/DIGITAL BOOTH DURING THE EVENT PROVIDE LEGAL BASIS TO ANY KIND OF LAWSUIT RESULTING IN INDEMNITY OF ANY NATURE TO THE PARTICIPANT/ARTIST FOR DIRECT, INDIRECT MORAL AND MATERIAL DAMAGES DONE AGAINST THE ORGANIZER REGARDLESS OF THE PURPOSES</strong>
<br /><br />
3. It is the PARTICIPANT/ARTIST entire responsibility to submit all required information to their AREA/DIGITAL BOOTH as well as the entering any links redirecting to their websites/stores/hotsites/social network profiles, and/or their own video platforms (“EXTERNAL WEBSITES”) that the PARTICIPANT/ARTIST wishes present to any EVENT VISITORS. These EXTERNAL WEBSITES do not present any technical integration with the event PLATFORM and should be informed in the PARTICIPANT’S AREA/DIGITAL BOOTH only for the purpose of redirecting users to an external website.
<br /><br />
4. It is <strong>the PARTICIPANT/ARTIST sole responsibility</strong> to handle the design, development, and operation of any EXTERNAL WEBSITES. The PARTICIPANT/ARTIST are also solely responsible for all activities and contents developed/displayed on his/her PARTICIPANT’S AREA/DIGITAL BOOTH and on his EXTERNAL WEBSITE as well as for any and all work or product displayed/sold in such areas regardless of their nature under all legal aspects and including, but not limited to, authorizations granted for such use/exposure/advertising/placement/livestreaming, and/or selling related to copyright laws in effect (regarding plagiarism/piracy ), use of image, sale/commercial representation, taxes, age rating or others. Also, the ORGANIZER will remain exempt of any liability regarding a PARTICIPANT’S AREA/DIGITAL BOOTH and EXTERNAL WEBSITES.
<br /><br />
5. It is also the <strong>PARTICIPANTS/ARTISTS sole responsibility</strong> to legally deal with any and all kinds of public data gathering and processing carried out on using an EXTERNAL WEBSITE in compliance with the Data Protection Law (13.709/18), thus exempting the ORGANIZER of any liability for any events or security incidents related to such data.
<br /><br />
6. <strong>All the terms and conditions in this agreement was entered into between the parties, the PARTICIPANT/ARTIST and the ORGANIZER, in a complementary manner to all terms and conditions established by CCXP WORLDS 2021 ATTENDANCE AGREEMENT</strong> as if fully written therein and including all relevant definitions, as well as in their <strong>ANNEXES and in the MANUALS of participation in CCXP WORLDS and general guidelines that were made available by the ORGANIZER for the PARTICIPANT/ARTIST</strong>. The PARTICIPANT/ARTIST must make observe regulations described in these documents without exception on pain of the imposition of a penalty based on consequences provided in such documents for what is considered a breach of contract.
<strong>The PARTICIPANT/ARTIST formally expresses the statement of agreement that he has read, understood and accepted these Terms and Conditions as well as any other conditions established on the other documents mentioned in item 6 and the Privacy Policy, stating that he/she is fully aware of the rights and obligations arising from them.</strong>`,

    creatorsCosplayAgreement: `<strong>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA CCXP WORLDS 2021</strong>
<br /><br />
Ao acessar a <strong>PLATAFORMA CCXP WORLDS 2021</strong> (“PLATAFORMA”), disponibilizada pela CCXP EVENTOS LTDA. (“<strong>ORGANIZADORA</strong>”) ao PARTICIPANTE/ARTISTA, com a exclusiva finalidade de permitir ao PARTICIPANTE/ARTISTA participar do evento denominado CCXP WORLDS 2021 (“<strong>EVENTO</strong>”), o PARTICIPANTE/ARTISTA, fica ciente e aceita que:
<br /><br />
1. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE, são disponibilizados pela ORGANIZADORA ao PARTICIPANTE/ARTISTA “<strong>NO ESTADO EM QUE SE ENCONTRA</strong>”. A ORGANIZADORA não oferece nenhuma garantia de funcionamento ou de condições especificas de funcionamento e conexão da PLATAFORMA e/ou do ESPAÇO/MESA VIRUTAL, tanto para o PARTICIPANTE/ARTISTA quanto para os visitantes do EVENTO.
<br /><br />
2. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE/ARTISTA podem não estar livres de interrupções ou erros, e <strong>NENHUMA FALHA/DEFEITO/INTERRUPÇÃO DE QUALQUER NATUREZA VERIFICADA NA PLATAFORMA E/OU NO ESPAÇO/MESA VIRTUAL DURANTE A REALIZAÇÃO DO EVENTO, DARÁ DIREITO A QUALQUER TIPO DE INDENIZAÇÃO, DE QUALQUER NATUREZA, AO PARTICIPANTE/ARTISTA, POR DANOS DIRETOS, INDIRETOS, MORAIS E MATERIAIS CONTRA A ORGANIZADORA, SEJA A QUE TÍTULO FOR</strong>.
<br /><br />
3. É de exclusiva responsabilidade do PARTICIPANTE/ARTISTA o preenchimento de suas informações no ESPAÇO/MESA VIRTUAL, e a inclusão dos “links” seu site/e-commerce/hotsite/redes sociais e/ou canais próprios a que o PARTICIPANTE/ARTISTA vier a direcionar os frequentadores do EVENTO (em conjunto ou isoladamente, “SITE EXTERNO”). O SITE EXTERNO não possui qualquer integração técnica à PLATAFORMA, devendo ser meramente informado no ESPAÇO/MESA VIRTUAL através do apontamento do link correspondente.
<br /><br />
4. É de <strong>exclusiva responsabilidade do PARTICIPANTE/ARTISTA</strong> o desenvolvimento, construção e operação do seu SITE EXTERNO, conforme melhor lhe aprouver. O PARTICIPANTE/ARTISTA será integral e exclusivamente responsável por toda e qualquer atividade e conteúdo, de qualquer natureza, que vier a desenvolver/veicular no seu ESPAÇO/MESA VIRTUAL e no seu SITE EXTERNO, bem como por todo e qualquer trabalho ou produto exposto/vendido em tais áreas, perante qualquer terceiro, sob todos os aspectos legais, - incluindo, mas não se limitando, às autorizações necessárias ao seu uso/exposição/publicidade/veiculação/transmissão e/ou comercialização, relacionadas à direitos autorais (plágio/pirataria), de imagem, de representação/venda, fiscais, tributários, classificação etária ou outros -, devendo manter a ORGANIZADORA livre e indene de qualquer responsabilidade em relação ao seu ESPAÇO/MESA VIRTUAL e SITE EXTERNO.
<br /><br />
5. O <strong>PARTICIPANTE/ARTISTA deverá responder, com exclusividade</strong>, por toda e qualquer coleta e tratamento de dados do público realizada no seu SITE EXTERNO, de acordo com o quanto disposto na <strong>Lei Geral de Proteção de Dados (Lei 13.709/18)</strong>, isentando  e mantendo a ORGANIZADORA livre e indene de qualquer responsabilidade por eventos ou incidentes de segurança relacionados à tal coleta e tratamento.
<br /><br />
6. <strong>Aplicam-se, ainda, de forma complementar a estes Termos e Condições de Uso da Plataforma CCXP Worlds 2021, todos os termos e condições estabelecidos no CONTRATO DE PARTICIPAÇÃO NA CCXP WORLDS</strong> celebrado entre o PARTICIPANTE/ARTISTA e a ORGANIZADORA, como se nele estivessem transcritos, integralmente, incluindo suas definições, assim como em seus <strong>ANEXOS e nos MANUAIS de participação na CCXP WORLDS e orientações gerais que foram disponibilizados pela ORGANIZADORA</strong> para o PARTICIPANTE/ARTISTA. O PARTICIPANTE/ARTISTA deverá observar todas as regras estabelecidas em tais documentos, sem exceção, sob pena de incorrer em descumprimento contratual, podendo sofrer as consequências previstas em tais documentos.
<strong>O PARTICIPANTE/ARTISTA declara expressamente que leu, compreendeu e aceitou estes Termos e Condições e os demais documentos mencionados no item 6 acima, e a Política de Privacidade e que está totalmente ciente dos direitos e obrigações que deles emanam.</strong>
<br /><br /><br />
<strong>CCXP WORLDS 2021 PLATFORM – TERMS OF USE</strong>
<br /><br />
By accessing <strong>CCXP WORLDS 2021’s WEBSITE</strong> (hereinafter referred to as “<strong>PLATFORM</strong>”) as made available by CCXP EVENTOS LTDA. (hereinafter referred to as “ORGANIZER”) to the PARTICIPANT/ARTIST with the sole purpose of allowing the aforementioned PARTICIPANT/ARTIST to participate in the event entitled CCXP WORLDS 2021 (hereinafter referred to as “<strong>EVENT</strong>”), the PARTICIPANT/ARTIST is aware and accepts that:
<br /><br />
1. THE PLATFORM as well as the PARTICIPANT'S AREA/DIGITAL BOOTH are made available by the ORGANIZER to the PARTICIPANT/ARTIST on a "AS IS" basis. The ORGANIZER does not offer any guarantees regarding operation nor any specific conditions of operation and internet connection regarding the PLATFORM and/or DIGITAL BOOTH/PARTICIPANT’S AREA, be it for the PARTICIPANT/ARTIST or the visitors of the EVENT.
<br /><br />
2. We would like to make clear that THE PLATFORM, as well as the PARTICIPANT’S AREA/ DIGITAL BOOTH, are not free of any interruptions or errors, and <strong>NO FAILURE/DEFECT/INTERRUPTION OF ANY NATURE RELATED TO THE PLATFORM AND/OR IN THE PARTICIPANT’S AREA/DIGITAL BOOTH DURING THE EVENT PROVIDE LEGAL BASIS TO ANY KIND OF LAWSUIT RESULTING IN INDEMNITY OF ANY NATURE TO THE PARTICIPANT/ARTIST FOR DIRECT, INDIRECT MORAL AND MATERIAL DAMAGES DONE AGAINST THE ORGANIZER REGARDLESS OF THE PURPOSES</strong>
<br /><br />
3. It is the PARTICIPANT/ARTIST entire responsibility to submit all required information to their AREA/DIGITAL BOOTH as well as the entering any links redirecting to their websites/stores/hotsites/social network profiles, and/or their own video platforms (“EXTERNAL WEBSITES”) that the PARTICIPANT/ARTIST wishes present to any EVENT VISITORS. These EXTERNAL WEBSITES do not present any technical integration with the event PLATFORM and should be informed in the PARTICIPANT’S AREA/DIGITAL BOOTH only for the purpose of redirecting users to an external website.
<br /><br />
4. It is <strong>the PARTICIPANT/ARTIST sole responsibility</strong> to handle the design, development, and operation of any EXTERNAL WEBSITES. The PARTICIPANT/ARTIST are also solely responsible for all activities and contents developed/displayed on his/her PARTICIPANT’S AREA/DIGITAL BOOTH and on his EXTERNAL WEBSITE as well as for any and all work or product displayed/sold in such areas regardless of their nature under all legal aspects and including, but not limited to, authorizations granted for such use/exposure/advertising/placement/livestreaming, and/or selling related to copyright laws in effect (regarding plagiarism/piracy ), use of image, sale/commercial representation, taxes, age rating or others. Also, the ORGANIZER will remain exempt of any liability regarding a PARTICIPANT’S AREA/DIGITAL BOOTH and EXTERNAL WEBSITES.
<br /><br />
5. It is also the <strong>PARTICIPANTS/ARTISTS sole responsibility</strong> to legally deal with any and all kinds of public data gathering and processing carried out on using an EXTERNAL WEBSITE in compliance with the Data Protection Law (13.709/18), thus exempting the ORGANIZER of any liability for any events or security incidents related to such data.
<br /><br />
6. <strong>All the terms and conditions in this agreement was entered into between the parties, the PARTICIPANT/ARTIST and the ORGANIZER, in a complementary manner to all terms and conditions established by CCXP WORLDS 2021 ATTENDANCE AGREEMENT</strong> as if fully written therein and including all relevant definitions, as well as in their <strong>ANNEXES and in the MANUALS of participation in CCXP WORLDS and general guidelines that were made available by the ORGANIZER for the PARTICIPANT/ARTIST</strong>. The PARTICIPANT/ARTIST must make observe regulations described in these documents without exception on pain of the imposition of a penalty based on consequences provided in such documents for what is considered a breach of contract.
<strong>The PARTICIPANT/ARTIST formally expresses the statement of agreement that he has read, understood and accepted these Terms and Conditions as well as any other conditions established on the other documents mentioned in item 6 and the Privacy Policy, stating that he/she is fully aware of the rights and obligations arising from them.</strong>`,

    specialsAgreement: `<strong>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA CCXP WORLDS 2021</strong>
<br /><br />
Ao acessar a <strong>PLATAFORMA CCXP WORLDS 2021</strong> (“PLATAFORMA”), disponibilizada pela CCXP EVENTOS LTDA. (“<strong>ORGANIZADORA</strong>”) ao PARTICIPANTE/ARTISTA, com a exclusiva finalidade de permitir ao PARTICIPANTE/ARTISTA participar do evento denominado CCXP WORLDS 2021 (“<strong>EVENTO</strong>”), o PARTICIPANTE/ARTISTA, fica ciente e aceita que:
<br /><br />
1. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE, são disponibilizados pela ORGANIZADORA ao PARTICIPANTE/ARTISTA “<strong>NO ESTADO EM QUE SE ENCONTRA</strong>”. A ORGANIZADORA não oferece nenhuma garantia de funcionamento ou de condições especificas de funcionamento e conexão da PLATAFORMA e/ou do ESPAÇO/MESA VIRUTAL, tanto para o PARTICIPANTE/ARTISTA quanto para os visitantes do EVENTO.
<br /><br />
2. A PLATAFORMA, assim como o ESPAÇO/MESA VIRTUAL do PARTICIPANTE/ARTISTA podem não estar livres de interrupções ou erros, e <strong>NENHUMA FALHA/DEFEITO/INTERRUPÇÃO DE QUALQUER NATUREZA VERIFICADA NA PLATAFORMA E/OU NO ESPAÇO/MESA VIRTUAL DURANTE A REALIZAÇÃO DO EVENTO, DARÁ DIREITO A QUALQUER TIPO DE INDENIZAÇÃO, DE QUALQUER NATUREZA, AO PARTICIPANTE/ARTISTA, POR DANOS DIRETOS, INDIRETOS, MORAIS E MATERIAIS CONTRA A ORGANIZADORA, SEJA A QUE TÍTULO FOR</strong>.
<br /><br />
3. É de exclusiva responsabilidade do PARTICIPANTE/ARTISTA o preenchimento de suas informações no ESPAÇO/MESA VIRTUAL, e a inclusão dos “links” seu site/e-commerce/hotsite/redes sociais e/ou canais próprios a que o PARTICIPANTE/ARTISTA vier a direcionar os frequentadores do EVENTO (em conjunto ou isoladamente, “SITE EXTERNO”). O SITE EXTERNO não possui qualquer integração técnica à PLATAFORMA, devendo ser meramente informado no ESPAÇO/MESA VIRTUAL através do apontamento do link correspondente.
<br /><br />
4. É de <strong>exclusiva responsabilidade do PARTICIPANTE/ARTISTA</strong> o desenvolvimento, construção e operação do seu SITE EXTERNO, conforme melhor lhe aprouver. O PARTICIPANTE/ARTISTA será integral e exclusivamente responsável por toda e qualquer atividade e conteúdo, de qualquer natureza, que vier a desenvolver/veicular no seu ESPAÇO/MESA VIRTUAL e no seu SITE EXTERNO, bem como por todo e qualquer trabalho ou produto exposto/vendido em tais áreas, perante qualquer terceiro, sob todos os aspectos legais, - incluindo, mas não se limitando, às autorizações necessárias ao seu uso/exposição/publicidade/veiculação/transmissão e/ou comercialização, relacionadas à direitos autorais (plágio/pirataria), de imagem, de representação/venda, fiscais, tributários, classificação etária ou outros -, devendo manter a ORGANIZADORA livre e indene de qualquer responsabilidade em relação ao seu ESPAÇO/MESA VIRTUAL e SITE EXTERNO.
<br /><br />
5. O <strong>PARTICIPANTE/ARTISTA deverá responder, com exclusividade</strong>, por toda e qualquer coleta e tratamento de dados do público realizada no seu SITE EXTERNO, de acordo com o quanto disposto na <strong>Lei Geral de Proteção de Dados (Lei 13.709/18)</strong>, isentando  e mantendo a ORGANIZADORA livre e indene de qualquer responsabilidade por eventos ou incidentes de segurança relacionados à tal coleta e tratamento.
<br /><br />
6. <strong>Aplicam-se, ainda, de forma complementar a estes Termos e Condições de Uso da Plataforma CCXP Worlds 2021, todos os termos e condições estabelecidos no CONTRATO DE PARTICIPAÇÃO NA CCXP WORLDS</strong> celebrado entre o PARTICIPANTE/ARTISTA e a ORGANIZADORA, como se nele estivessem transcritos, integralmente, incluindo suas definições, assim como em seus <strong>ANEXOS e nos MANUAIS de participação na CCXP WORLDS e orientações gerais que foram disponibilizados pela ORGANIZADORA</strong> para o PARTICIPANTE/ARTISTA. O PARTICIPANTE/ARTISTA deverá observar todas as regras estabelecidas em tais documentos, sem exceção, sob pena de incorrer em descumprimento contratual, podendo sofrer as consequências previstas em tais documentos.
<strong>O PARTICIPANTE/ARTISTA declara expressamente que leu, compreendeu e aceitou estes Termos e Condições e os demais documentos mencionados no item 6 acima, e a Política de Privacidade e que está totalmente ciente dos direitos e obrigações que deles emanam.</strong>
<br /><br /><br />
<strong>CCXP WORLDS 2021 PLATFORM – TERMS OF USE</strong>
<br /><br />
By accessing <strong>CCXP WORLDS 2021’s WEBSITE</strong> (hereinafter referred to as “<strong>PLATFORM</strong>”) as made available by CCXP EVENTOS LTDA. (hereinafter referred to as “ORGANIZER”) to the PARTICIPANT/ARTIST with the sole purpose of allowing the aforementioned PARTICIPANT/ARTIST to participate in the event entitled CCXP WORLDS 2021 (hereinafter referred to as “<strong>EVENT</strong>”), the PARTICIPANT/ARTIST is aware and accepts that:
<br /><br />
1. THE PLATFORM as well as the PARTICIPANT'S AREA/DIGITAL BOOTH are made available by the ORGANIZER to the PARTICIPANT/ARTIST on a "AS IS" basis. The ORGANIZER does not offer any guarantees regarding operation nor any specific conditions of operation and internet connection regarding the PLATFORM and/or DIGITAL BOOTH/PARTICIPANT’S AREA, be it for the PARTICIPANT/ARTIST or the visitors of the EVENT.
<br /><br />
2. We would like to make clear that THE PLATFORM, as well as the PARTICIPANT’S AREA/ DIGITAL BOOTH, are not free of any interruptions or errors, and <strong>NO FAILURE/DEFECT/INTERRUPTION OF ANY NATURE RELATED TO THE PLATFORM AND/OR IN THE PARTICIPANT’S AREA/DIGITAL BOOTH DURING THE EVENT PROVIDE LEGAL BASIS TO ANY KIND OF LAWSUIT RESULTING IN INDEMNITY OF ANY NATURE TO THE PARTICIPANT/ARTIST FOR DIRECT, INDIRECT MORAL AND MATERIAL DAMAGES DONE AGAINST THE ORGANIZER REGARDLESS OF THE PURPOSES</strong>
<br /><br />
3. It is the PARTICIPANT/ARTIST entire responsibility to submit all required information to their AREA/DIGITAL BOOTH as well as the entering any links redirecting to their websites/stores/hotsites/social network profiles, and/or their own video platforms (“EXTERNAL WEBSITES”) that the PARTICIPANT/ARTIST wishes present to any EVENT VISITORS. These EXTERNAL WEBSITES do not present any technical integration with the event PLATFORM and should be informed in the PARTICIPANT’S AREA/DIGITAL BOOTH only for the purpose of redirecting users to an external website.
<br /><br />
4. It is <strong>the PARTICIPANT/ARTIST sole responsibility</strong> to handle the design, development, and operation of any EXTERNAL WEBSITES. The PARTICIPANT/ARTIST are also solely responsible for all activities and contents developed/displayed on his/her PARTICIPANT’S AREA/DIGITAL BOOTH and on his EXTERNAL WEBSITE as well as for any and all work or product displayed/sold in such areas regardless of their nature under all legal aspects and including, but not limited to, authorizations granted for such use/exposure/advertising/placement/livestreaming, and/or selling related to copyright laws in effect (regarding plagiarism/piracy ), use of image, sale/commercial representation, taxes, age rating or others. Also, the ORGANIZER will remain exempt of any liability regarding a PARTICIPANT’S AREA/DIGITAL BOOTH and EXTERNAL WEBSITES.
<br /><br />
5. It is also the <strong>PARTICIPANTS/ARTISTS sole responsibility</strong> to legally deal with any and all kinds of public data gathering and processing carried out on using an EXTERNAL WEBSITE in compliance with the Data Protection Law (13.709/18), thus exempting the ORGANIZER of any liability for any events or security incidents related to such data.
<br /><br />
6. <strong>All the terms and conditions in this agreement was entered into between the parties, the PARTICIPANT/ARTIST and the ORGANIZER, in a complementary manner to all terms and conditions established by CCXP WORLDS 2021 ATTENDANCE AGREEMENT</strong> as if fully written therein and including all relevant definitions, as well as in their <strong>ANNEXES and in the MANUALS of participation in CCXP WORLDS and general guidelines that were made available by the ORGANIZER for the PARTICIPANT/ARTIST</strong>. The PARTICIPANT/ARTIST must make observe regulations described in these documents without exception on pain of the imposition of a penalty based on consequences provided in such documents for what is considered a breach of contract.
<strong>The PARTICIPANT/ARTIST formally expresses the statement of agreement that he has read, understood and accepted these Terms and Conditions as well as any other conditions established on the other documents mentioned in item 6 and the Privacy Policy, stating that he/she is fully aware of the rights and obligations arising from them.</strong>`,

    tableBlocked: 'Seu acesso está temporariamente bloqueado para moderação da equipe CCXP World.',
  },

  generalCache: {
    registers: '{num} rotas cacheadas',
  },

  generalCustomers: {
    registers: '{num} clientes',
  },

  eventSchedule: {
    registers: '{num} eventos cadastrados',
    eventInfo: 'Informações do Evento',
    image: 'Imagem do Evento',
    artists: 'Artistas',
    credentialType: 'Tipo da Credencial',
    calendar: 'Quando vai acontecer',
    date: 'Dia',
    startDate: 'Começo',
    endDate: 'Final',
    imageInstructions: '* mínimo de 1024x600',
    buttonAddTalent: 'Adicionar (até 10)',
  },

  sections: {
    customers: 'Clientes',
    users: 'Usuários',
    tags: 'Tags',
    artists: 'Artistas',
    myProfile: 'Meu perfil',
    'artists-valley': 'Artists Valley',
    experiences: 'Experiences',
    studios: 'Studios',
    'creators-cosplay': 'Creators & Cosplay',
    specials: 'Especiais',
    stages: 'Palcos',
    schedule: 'Schedule',
    'studios-playlist': 'Studios Playlist',
    talents: 'Talentos',
    talentCategories: 'Categorias dos talentos',
    download: 'Download',
    redirect: 'Redirecionamento',
    stores: 'Lojas Mercado Livre',
  },

  talents: {
    registers: '{num} talentos cadastrados',
  },

  usersManagement: {
    create: 'Criar novo usuário',
    edit: 'Editar usuário',
    registers: '{num} usuários cadastrados',
    profileTypeArtist: 'Artista',
    artist: 'Artista',
    moderator: 'Moderador',
    titlePermissions: 'Permissões de acesso',
  },

  stage: {
    live: 'Ao vivo',
  },

  artistsManagement: {
    registers: '{num} artistas cadastrados',
    aboutEN: 'Descrição / EN-US',
    aboutPT: 'Descrição / PT-BR',
    videos: 'Vídeos',
    marketplace: 'Vitrine',
    gallery: 'Imagens',
    galleryAddItem: 'Adicione uma nova imagem a Galeria',
    showroomAddItem: 'Adicione uma nova imagem a sua Vitrine',
    galleryYour: 'Sua Galeria',
    showroomYour: 'Sua Vitrine',
    videoYour: 'Seus Vídeos',
    info: 'Informações',
    tags: 'Tags',
    modules: 'Conteúdo',
    addTag: 'Adicionar tag',
    marketplaceAddItem: 'Adicionar item a vitrine',
    video: 'Vídeo',
    profilePicture: 'Imagem de perfil',
    country: 'País',
    additionalInfo: 'Informações adicionais',
    selectItem: 'Selecione',
    liveInfo: 'Ao vivo no Youtube',
    switchLive: 'Estou ao vivo no Youtube',
    liveLink: 'Link do embed',
    languages: 'Línguas',
    filterContract: 'Contrato assinado?',
    filterLive: 'Ao vivo?',
    tagsDisclaimer: 'Inclua todas as tags que tenham a ver com o seu trabalho e/ou com os seus produtos disponíveis.',
    socialMediaDisclaimer: 'Os links das suas redes sociais ficarão visíveis para todos em sua página de perfil',
    additionalInfoDisclaimer: 'Por favor preencha as informações abaixo para que as pessoas possam te encontrar mais facilmente',
    languageDisclaimer: 'Selecione quantas quiser',
    additionalLives: 'Por favor, adicione todos os dias e horários das lives',
    approved: 'Moderado?',
  },

  pushNotification: {
    messageEN: 'Mensagem / EN-US',
    messagePT: 'Mensagem / PT-BR',
    genders: 'Gêneros',
    profilePicture: 'Foto de perfil',
    pushImage: 'Imagem da notificação',
    ageRange: 'Faixa etária',
    customersReached: 'Clientes atingidos',
    sendPush: 'Enviar notificação',
    uf: 'UF',
    stages: 'Palcos',
    scheduledTo: 'Agendado para',
    date: 'Data',
    time: 'Hora',
    limitByPercent: 'Limitar em %',
    limitByN: 'Limitar em N',
    sentIn: 'Enviado em',
    titlePT: 'Título / PT-BR:',
    titleEN: 'Título / EN-US:',
    sentNotificationMessage: 'A Push Notification será enviada e essa ação não poderá ser desfeita.',
    continue: 'Deseja continuar?',
  },

  welcome: {
    title: 'Seja bem vindo ao Painel Administrativo.',
    message: 'Selecione ao lado o módulo desejado.',
  },

  footer: {
    message: 'Desenvolvido por Sioux Group.',
  },

  mercadoLivreManagement: {
    registers: '{num} lojas cadastradas',
    // aboutEN: 'Descrição / EN-US',
    // aboutPT: 'Descrição / PT-BR',
    // videos: 'Vídeos',
    // marketplace: 'Vitrine',
    // gallery: 'Imagens',
    // galleryAddItem: 'Adicione uma nova imagem a Galeria',
    // showroomAddItem: 'Adicione uma nova imagem a sua Vitrine',
    // galleryYour: 'Sua Galeria',
    // showroomYour: 'Sua Vitrine',
    // videoYour: 'Seus Vídeos',
    // info: 'Informações',
    // tags: 'Tags',
    // modules: 'Conteúdo',
    // addTag: 'Adicionar tag',
    // marketplaceAddItem: 'Adicionar item a vitrine',
    // video: 'Vídeo',
    // profilePicture: 'Imagem de perfil',
    // country: 'País',
    // additionalInfo: 'Informações adicionais',
    // selectItem: 'Selecione',
    // liveInfo: 'Ao vivo no Youtube',
    // switchLive: 'Estou ao vivo no Youtube',
    // liveLink: 'Link do embed',
    // languages: 'Línguas',
    // filterContract: 'Contrato assinado?',
    // filterLive: 'Ao vivo?',
    // languageDisclaimer: 'Selecione quantas quiser',
    // additionalLives: 'Por favor, adicione todos os dias e horários das lives',
    // approved: 'Moderado?',
  },
};
